/*--------------------------------------------------------------
MAILTO  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Open
- Submit
- Done
- Reset
--------------------------------------------------------------*/

class Mailto extends Popup {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor(id) {
		super(id);
		this.pending = false;
		this.query = `
		mutation ($from: [String]!, $to: String!, $subject: String!, $message: String!) {
			sent: sendMessage (from: $from, to: $to, subject: $subject, message: $message)
		}`;
		this.form = this.popup.get('form');
		this.close_btn = this.form.get('.close');
		this.response = this.popup.get('.response');
		this.res_success = this.response.get('.success');
		this.res_failure = this.response.get('.failure');
		this.btn_ok = this.response.get('button');
		this.bind();
	}

	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind() {
		this.form.onsubmit = e => this.submit(e);
		this.btn_ok.onclick = _ => this.close();
		this.close_btn.onclick = _ => this.close();
	}

	/*--------------------------------------------------------------
	-	Open
	--------------------------------------------------------------*/
	open(id, ref) {
		this.reset();
		super.open();
		this.commercial_id = id;
		this.ref = ref;
	}

	open_H() {
		this.reset();
		super.open();
	}

	/*--------------------------------------------------------------
	-	Submit
	--------------------------------------------------------------*/
	submit(e) {
		event.preventDefault();
		if (this.pending) return;
		this.loading();
		let {
			email,
			firstname,
			lastname,
			phone,
			society,
			message,
			subject,
		} = new Form(this.form).parse();
		subject = 'Message en provenance du site SCAMAC-IMMO - ' + subject;
		let body = `Prénom : ${firstname}\n`;
		body += `Nom : ${lastname}\n`;
		body += `Email : ${email}\n`;
		body += `Téléphone : ${phone}\n`;
		if (society) {
			body += `Société : ${society}\n`;
		}
		if (this.ref) {
			body += `\nRéférence du lot concerné : ${this.ref}\n`;
		}
		body += `\nCorps du message :\n\n${message}\n\n\n\n`;
		let variables = {
			from: [`${firstname} ${lastname}`, email],
			to: this.commercial_id,
			subject,
			message: body,
		};
		ajax.graphql({ query: this.query, variables }).then(
			res => {
				this.pending = false;
				if (res.errors) {
					console.error(res.errors);
					this.done(false);
				} else {
					this.form.reset();
					this.done(true);
					dataLayer.push({ event: 'contact-form-sent' });
				}
			},
			err => {
				console.error(err);
				this.pending = false;
				this.done(false);
			}
		);
	}

	/*--------------------------------------------------------------
	-	Loading
	--------------------------------------------------------------*/
	loading() {
		this.pending = true;
		this.form.classList.add('hidden');
		this.popup.classList.add('loading');
	}

	/*--------------------------------------------------------------
	-	Done
	--------------------------------------------------------------*/
	done(ok) {
		this.pending = false;
		this.popup.classList.remove('loading');
		this.response.classList.remove('hidden');
		if (ok) {
			this.res_success.classList.remove('hidden');
		} else {
			this.response.classList.add('fail');
			this.res_failure.classList.remove('hidden');
		}
	}

	/*--------------------------------------------------------------
	-	Reset
	--------------------------------------------------------------*/
	reset() {
		this.form.classList.remove('hidden');
		this.response.classList.add('hidden');
		this.response.classList.remove('fail');
		this.res_success.classList.add('hidden');
		this.res_failure.classList.add('hidden');
	}
}
