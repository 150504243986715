/*--------------------------------------------------------------
VIGNETTE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Fetch
- Gen
- Append
--------------------------------------------------------------*/

class Vignette {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		this.query = `query ($filters: filtersLots!, $limit: Int) {
			searchLots (filters: $filters, limit: $limit) {
				_id
				url
				adresse {
					ville {
						_id
						nom
						code_postal
					}
				}
				general {
					annonce
					type
				}
				statut {
					a_louer
					a_vendre
					commerciaux {
						_id
						nom
						prenom
						portable
						photo {
							src
						}
					}
				}
				vente {
					prix
				}
				location {
					loyer_annuel {
						ht_hc
					}
				}
				superficie_totale {
					totale
					batiment
				}
				images {
					src
					src_square: src(size: SQUARE)
					alt
				}
			}
		}`;
	}

	/*--------------------------------------------------------------
	-	Fetch
	--------------------------------------------------------------*/
	fetch(vars) {
		let selectedTypes;

		if (vars.filters.types) {
			selectedTypes = vars.filters.types.map((type) => {
				if (type == 1) {
					return 'Hangar';
				}
				if (type == 2) {
					return 'Entrepôt';
				}
				if (type == 3) {
					return 'Entrepôt frigorifique';
				}
				if (type == 4) {
					return 'Atelier';
				}
				if (type == 16) {
					return "Local d'activités";
				}
				if (type == 17) {
					return 'Local commercial';
				}
				if (type == 18) {
					return 'Bureau';
				}
				if (type == 19) {
					return 'Terrain';
				}
				if (type == 20) {
					return 'Locaux mixtes';
				}
			});
		}

		if (vars.filters.villes) {
			vars.filters.villes = vars.filters.villes.filter(
				(v) => [null, undefined, ''].indexOf(v) == -1
			);
		}

		let filters = {
			adresse: {
				ville: vars.filters.villes ? vars.filters.villes : null,
			},
			general: {
				type: selectedTypes || null,
			},
			statut: {
				a_vendre: vars.filters.vente ? true : null,
				a_louer: vars.filters.location ? true : null,
			},
			surfaces: {
				superficie_min: Number(vars.filters.surface_min) || null,
				superficie_max: Number(vars.filters.surface_max) || null,
			},
			synchro: {
				web: true,
			},
		};

		return new Promise((resolve, error) => {
			ajax
				.graphql({
					query: this.query,
					variables: { filters, limit: vars.limit },
				})
				.then(({ data }) => {
					let frag = document.createDocumentFragment();
					data.searchLots.forEach((lot) => {
						frag.appendChild(this.gen(lot, vars));
					});
					resolve({ frag, length: data.searchLots.length });
				});
		});
	}

	/*--------------------------------------------------------------
	-	Gen
	--------------------------------------------------------------*/
	gen(lot, vars) {
		let img;
		if (lot.images) {
			img = {
				src: lot.images[0] ? lot.images[0].src : '/img/annonce-defaut.jpg',
				alt: lot.images[0] && lot.images[0].alt ? lot.images[0].alt : '',
			};
		}

		let tel;
		if (lot.statut.commerciaux) {
			tel = 'tel: ' + lot.statut.commerciaux[0].portable;
		}

		let statut;
		if (lot.statut.a_vendre && lot.statut.a_louer) {
			statut = 'À VENDRE ET À LOUER';
		} else if (lot.statut.a_vendre) {
			statut = 'À VENDRE';
		} else if (lot.statut.a_louer) {
			statut = 'À LOUER';
		}

		let vign = tmpl.render('vignette', {
			link: lot.url,
			img: img ? img.src : null,
			alt: img ? img.alt : null,
			title: `${statut} : <strong>${lot.general.type}</strong>`,
			adresse: `${lot.adresse.ville.nom} (${Math.floor(
				lot.adresse.ville.code_postal / 1000
			)})`,
			desc: lot.general.annonce || '',
			surface: lot.superficie_totale.batiment.toLocaleString('fr-FR') + ' m²',
			prix_vente:
				lot.vente && lot.vente.prix
					? lot.vente.prix.toLocaleString('fr-FR') + ' €'
					: null,
			prix_location:
				lot.location &&
				lot.location.loyer_annuel &&
				lot.location.loyer_annuel.ht_hc
					? lot.location.loyer_annuel.ht_hc.toLocaleString('fr-FR') +
					  ' €<span> / an HT TC</span>'
					: null,
			tel: tel || '',
			id: lot.statut.commerciaux ? lot.statut.commerciaux[0]._id : '0',
		});
		let li = document.createElement('li');
		li.appendChild(vign);
		return li;
	}

	/*--------------------------------------------------------------
	-	Append
	--------------------------------------------------------------*/
	append(selector, vars) {
		const container = $$.first(selector);
		this.fetch(vars).then(({ frag, length }) => {
			container.appendChild(frag);
			container.classList.add('loaded');
			if (length === 0) {
				const parent = container.parentNode;
				parent.parentNode.removeChild(parent);
			}
			page.maitlo();
		});
	}

	removeChildren(selector) {
		let element = document.querySelector(selector);
		if (!element) {
			return;
		}

		while (element.hasChildNodes()) {
			element.removeChild(element.lastChild);
		}
	}
}
