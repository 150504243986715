/*--------------------------------------------------------------
REVEAL >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
--------------------------------------------------------------*/



class Reveal {

	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (id) {
		this.id = $$.id(id);
		this.window_height = window.innerHeight - 200;
		this.elements = $$.all('.show');
		this.delete = $$.all('.reveal');
		this.reveal();
		window.on('scroll', _ => this.reveal());
	}

	reveal () {
		this.elements.forEach(el => {
			let {top} = el.getBoundingClientRect();
			if (top < this.window_height && !el.classList.contains('reveal') && !el.parentNode.classList.contains('reveal')){
				el.classList.add('on');
			}
		});
	}
}
