/*--------------------------------------------------------------
SCROLL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Animate
- Scroll
--------------------------------------------------------------*/



class Scroll {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (el) {
		this.btn = el;
		this.btn.onclick = e => this.animate(e);
		this.offset = $$.id('header').clientHeight;
		this.body = $$.first('.main');
	}



	/*--------------------------------------------------------------
	-	Animate
	--------------------------------------------------------------*/
	animate (e) {
		let target = $$.id(this.btn.hash.substr(1));
		if (!target) return;
		e.preventDefault();
		this.disableScroll();
		let offset = target.getBoundingClientRect().top;
		this.body.style.transition = (offset*1.5/1000) + 's cubic-bezier(.7, 0, .3, 1)';
		this.body.style.transform = `translateY(-${offset - this.offset}px)`;
		new Timeout(offset*1.5, _ => {
			let newScroll = window.pageYOffset + offset - this.offset;
			window.scrollTo(0, newScroll);
			this.body.style.transition = `none`;
			this.body.style.transform = `none`;
			this.enableScroll();
		});
	}



	/*--------------------------------------------------------------
	-	Scroll
	--------------------------------------------------------------*/
	disableScroll () {
		window.on('mousewheel', this.prevent);
		window.on('DOMMouseScroll', this.prevent);
		window.on('touchmove', this.prevent);
	}
	enableScroll () {
		window.off('mousewheel', this.prevent);
		window.off('DOMMouseScroll', this.prevent);
		window.off('touchmove', this.prevent);
	}
	prevent (e) {
		e.preventDefault();
	}



}
