/*--------------------------------------------------------------
SLIDER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Go2
- Auto
- Next
--------------------------------------------------------------*/

class Slider {
	/*--------------------------------------------------------------
		Constructor
	--------------------------------------------------------------*/
	constructor(id, delay, slides, buttons) {
		this.delay = delay;
		this.parent = $$.id(id);
		this.slides = this.parent.getAll(slides);
		this.btns = this.parent.getAll(buttons);
		this.btns.forEach(btn => {
			btn.onclick = () => this.go2(btn.value);
		});
		this.index = 0;
		if (window.innerWidth < '430') {
			this.go2(1);
		} else {
			if (delay) this.auto();
		}
	}

	/*--------------------------------------------------------------
		Go2
	--------------------------------------------------------------*/
	go2(id) {
		this.index = id;
		this.slides.forEach((slide, i) => {
			if (i > id) {
				slide.classList.add('right');
				slide.classList.remove('left');
				slide.classList.remove('on');
				this.btns[i].checked = false;
			} else if (i < id) {
				slide.classList.remove('right');
				slide.classList.remove('on');
				slide.classList.add('left');
				this.btns[i].checked = false;
			} else {
				slide.classList.remove('right');
				slide.classList.remove('left');
				slide.classList.add('on');
				this.btns[i].checked = true;
			}
		});
		if (this.timeout) this.timeout.reset();
	}

	/*--------------------------------------------------------------
		Auto
	--------------------------------------------------------------*/
	auto() {
		this.timeout = new Timeout(this.delay, _ => this.next());
	}

	/*--------------------------------------------------------------
		Next
	--------------------------------------------------------------*/
	next() {
		let id = (this.index + 1) % this.slides.length;
		this.go2(id);
		this.timeout.reset();
	}
}
