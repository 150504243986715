/*--------------------------------------------------------------
POPUP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Alert
- Close
--------------------------------------------------------------*/



class Popup {


	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (id) {
		this.popup = $$.id(id);
		this.cache = this.popup.get('.cache');
		this.wrapper = this.popup.get('.wrapper');
		this.cache.onclick = _ => this.close();
	}



	/*--------------------------------------------------------------
	-	Alert
	--------------------------------------------------------------*/
	open (message) {
		if (message) {
			this.wrapper.innerHTML = message;
		}
		this.popup.classList.add('on');
	}


	/*--------------------------------------------------------------
	-	Close
	--------------------------------------------------------------*/
	close () {
		this.popup.classList.remove('on');
	}



}
