/*--------------------------------------------------------------
MAP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Tabs
--------------------------------------------------------------*/


class Gmap {
	constructor (id) {
		var uluru = {lat: 48.829766, lng: 2.316316};
		var map = new google.maps.Map(document.getElementById(id), {
			zoom: 16,
			center: uluru
		});
		var image = 'http://scamac.web.adelios.fr/img/emails/icone.png';
		var marker = new google.maps.Marker({
			position: uluru,
			map: map,
			icon: image
		});
	}
}

class contactMap {
	constructor () {
		this.boulogne = {lat: 48.8377047, lng: 2.249672};
		this.bussy = {lat: 48.8438715, lng: 2.6630038};
		this.i = 0;
		this.list = [
			'http://scamac.dev.adelios.fr/misc/boulogne-billancourt.kml?a=2',
			'http://scamac.dev.adelios.fr/misc/bussy-st-martin.kml?a=2',
		];

		this.init();
		this.set_event();
	}

	init () {
		this.map = new google.maps.Map($$.id('carte'), {
			zoom: 9,
			center: this.boulogne,

		});
		var image = 'http://scamac.dev.adelios.fr/img/pictos/pin2.png';
		this.marker = new google.maps.Marker({
			position: this.boulogne,
			map: this.map,
			icon:image
		});
		this.marker2 = new google.maps.Marker({
			position: this.bussy,
			map: this.map,
			icon:image
		});
		this.kmlLayer = new google.maps.KmlLayer(this.list[this.i], {
			suppressInfoWindows: true,
			preserveViewport: true,
			map: this.map
		});
	}

	update () {
		this.kmlLayer = new google.maps.KmlLayer(this.list[this.i], {
			suppressInfoWindows: true,
			preserveViewport: true,
			map: this.map
		});
	}

	set_event () {
		let addresses = $$.all('#contact .addresses a');
		addresses.forEach((el, i) => {
			el.on('click', e => {
				e.preventDefault();
				this.kmlLayer.setMap(null);
				this.i = i;
				this.update();
			});
		})
		this.marker.addListener('click', _ => {
			this.kmlLayer.setMap(null);
			this.kmlLayer = new google.maps.KmlLayer(this.list[1], {
				suppressInfoWindows: true,
				preserveViewport: true,
				map: this.map
			});
        });
		this.marker2.addListener('click', _ => {
			this.kmlLayer.setMap(null);
			this.kmlLayer = new google.maps.KmlLayer(this.list[0], {
				suppressInfoWindows: true,
				preserveViewport: true,
				map: this.map
			});
        });
	}

}
