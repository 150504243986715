/*--------------------------------------------------------------
MODULE HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Active Tab
- Change Project
- Submit Proprio
--------------------------------------------------------------*/

class ModuleHome {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor(id, search_home) {
		this.module = $$.id(id);
		this.search_home = search_home;
		this.tabs = this.module.getAll('.tabs button');
		this.forms = this.module.getAll('form');
		this.proprio = $$.id('proprio_form');
		this.proprio_btn = this.proprio.get('button');
		this.bind();
	}

	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind() {
		this.tabs.forEach(btn => {
			let id = btn.getAttribute('data-tab');
			btn.on('click', _ => this.active_tab(btn, id));
			if (btn.value) {
				btn.on('click', _ => this.change_project(btn));
			}
		});
		this.proprio.onsubmit = e => this.submit_proprio(e);
	}

	/*--------------------------------------------------------------
	-	Active Tab
	--------------------------------------------------------------*/
	active_tab(btn, id) {
		this.tabs.forEach(tab => tab.classList.remove('on'));
		this.forms[0].classList.remove('first');
		this.forms.forEach(form => form.classList.remove('on'));

		btn.classList.add('on');
		$$.id(id).classList.add('on');
	}

	/*--------------------------------------------------------------
	-	Change Project
	--------------------------------------------------------------*/
	change_project(btn) {
		$$.first('input.project').value = btn.value;
		this.search_home.update();
	}

	/*--------------------------------------------------------------
	-	Submit Proprio
	--------------------------------------------------------------*/
	submit_proprio(e) {
		e.preventDefault();
		this.proprio_btn.disabled = true;
		let { name, email, phone } = new Form(this.proprio).parse();
		let subject = `Site web SCAMAC-IMMO - Propriétaire : ${name}`;
		let message =
			`Nom : ${name}\n` +
			`Téléphone : ${phone}\n` +
			`Email : ${email}\n\n` +
			`Cet email a été envoyé depuis le formulaire Propriétaire du site SCAMAC-IMMO.`;
		ajax
			.graphql({
				query: `mutation ($from: [String]!, $to: String!, $subject: String!, $message: String!) {
				sendMessage (from: $from, to: $to, subject: $subject, message: $message)
			}`,
				variables: {
					from: [name, email],
					to: '0',
					subject,
					message,
				},
			})
			.then(({ data: { sendMessage } }) => {
				this.proprio_btn.disabled = false;
				if (sendMessage) {
					this.proprio.reset();
					popup.open(
						"<p>Merci, vos coordonnées ont bien été envoyées.<br> Nous allons vous contacter d'ici peu.</p>"
					);
				} else {
					popup.open('<p>Une erreur est survenue. Veuillez réessayer.</p>');
				}
			});
	}
}
