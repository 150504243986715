/*--------------------------------------------------------------
TEMPLATE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Preload
- Render
--------------------------------------------------------------*/

class Template {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		this.datasets = [
			'src',
			'alt',
			'href',
			'textContent',
			'innerHTML',
			'onclick',
			'data-id',
		];
		this.limits = ['textContent', 'innerHTML'];
		this.templates = [];
	}

	/*--------------------------------------------------------------
	-	Preload
	--------------------------------------------------------------*/
	preload(templates) {
		return new Promise((resolve, reject) => {
			templates.forEach((template, index) => {
				if (!this.templates[template]) {
					ajax.get(`/templates/${template}.html`).then(res => {
						let dom = document.createDocumentFragment();
						let div = document.createElement('div');
						div.innerHTML = res;
						let length = div.children.length - 1;
						let i = -1;
						while (i++ < length) {
							dom.appendChild(div.children[i].cloneNode(true));
						}
						this.templates[template] = dom;
						if (index == templates.length - 1) {
							resolve();
						}
					});
				} else {
					if (index == templates.length - 1) {
						resolve();
					}
				}
			});
		});
	}

	/*--------------------------------------------------------------
	-	Render
	--------------------------------------------------------------*/
	render(template, data) {
		let dom = this.templates[template].cloneNode(true);

		dom.getAll('._tmpl_').forEach(el => {
			this.datasets.forEach(d => {
				let attr = el.getAttribute('data-' + d);
				if (d === 'src') {
					el.onerror = _ => el.delete();
					el[d] = data[attr];
				} else if (d === 'data-id') {
					el.setAttribute(d, data[attr]);
				} else if (d === 'onclick') {
					el.setAttribute(d, data[attr]);
				} else if (attr) {
					el[d] = data[attr];
				}
			});

			let limit;
			if ((limit = el.getAttribute('data-limit')) && limit.length > 0) {
				this.limits.forEach(l => {
					if (el.getAttribute('data-' + l))
						el[l] = el[l].substring(0, limit) + '...';
				});
			}
		});

		dom.getAll('._if_').forEach(el => {
			let iff = el.getAttribute('data-if');
			if (!data[iff] || data[iff].length === 0) el.delete();
		});

		return dom;
	}
}
