/*--------------------------------------------------------------
FORM  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Serialize
- Parse
--------------------------------------------------------------*/



class Form {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (form) {
		this.form = form;
	}



	/*--------------------------------------------------------------
	-	Serialize
	--------------------------------------------------------------*/
	serialize () {
		let params = this.parse();
		let url = [];
		Object.keys(params).forEach(k => {
			url.push(k + '=' + encodeURI(params[k]));
		});
		return url.join('&');
	}



	/*--------------------------------------------------------------
	-	Parse
	--------------------------------------------------------------*/
	parse () {
		let els = this.form.elements;
		let params = {};
		els.forEach((el, i) => {
			// radio
			if (el.type == 'radio') {
				if (el.checked)
				params[el.name] = el.value;
			}
			// checkbox
			else if (el.type == 'checkbox') {
				if (el.checked) {
					if (!params[el.name])
					params[el.name] = [];
					params[el.name].push(el.value);
				}
			}
			// text
			else if (el.value) {
				if (el.hasAttribute('data-sanitize')) {
					params[el.name] = el.value.replace(/ /g, '').toUpperCase();
				} else {
					params[el.name] = el.value;
				}
			}
		});
		return params;
	}



	/*--------------------------------------------------------------
	-	unSerialize
	--------------------------------------------------------------*/
	unSerialize (params) {
		Object.keys(params).forEach(k => {

			let elements = $$.all(`input[name=${k}]`);

			if (elements.length > 1) {
				if(params[k]) {
					let values = params[k].split(',');
					elements.forEach(el => {
						if (values.indexOf(el.value) != -1) {
							el.checked = true;
						}
					});
				}
			}
			else {
				if(elements[0] && params[k]) {
					elements[0].value = decodeURIComponent(params[k]);
				}
			}
		});
	}


}
