/*--------------------------------------------------------------
CHAT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Talk
- DDP
- Bind
- Bell
- Scroll down
- Get Chat BDD
- Toggle Chat box
- Create Chat box
- Create Message
- Submit Message
- Robot
- Loader
--------------------------------------------------------------*/

class Chat {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor({ ssl, btn, host, robotDelay, waitForAdmin }) {
		this.btn = btn;
		this.robotDelay = robotDelay;
		this.waitForAdmin = waitForAdmin;
		this.admin_here = false;
		this.robot_step = 'ask_for_email';
		this.ding = new Audio('/misc/ding.mp3');
		this.closed = true;
		this.tab_focused = true;
		this.page_title = document.title;
		this.user_data = {};
		this.rectangle = $$.first('.chat');

		// websocket
		const websocket_type = ssl ? 'wss' : 'ws';
		this.ddp = new ChatDDPClient({
			endpoint: `${websocket_type}://${host}/websocket`,
			SocketConstructor: WebSocket,
		});

		this.session_id = sessionStorage.getItem('chat_session_id') || random.id();
		this.create_chat_box();
		this.bind();
	}

	/*--------------------------------------------------------------
	-	Talk
	--------------------------------------------------------------*/
	client_says(msg, infos = null) {
		this.user_data = Object.assign(this.user_data, infos);
		this.ddp.method('addChatMessage', [
			msg,
			this.session_id,
			this.user_data,
			true,
		]);
	}
	admin_says(msg) {
		this.ddp.method('addChatMessage', [msg, this.session_id, this.user_data]);
	}
	robot_says(msg, error = false) {
		this.ddp.method('addChatMessage', [
			msg,
			this.session_id,
			this.user_data,
			false,
			true,
			error,
		]);
	}
	change_step(step) {
		this.ddp.method('addChatStep', [step, this.session_id]);
		this.robot_step = step;
		this.robot_continue();
	}

	/*--------------------------------------------------------------
	-	DDP
	--------------------------------------------------------------*/
	ddp_connected() {
		console.log('%c😄 Chat box connected', 'color: #7bc130');

		// get the session_id or set it if doesn't exist
		if (sessionStorage && !sessionStorage.getItem('chat_session_id')) {
			sessionStorage.setItem('chat_session_id', this.session_id);
		}

		// subscribe to the 'chat' publication
		this.ddp.sub('Chat.messagesList', [this.session_id]);
	}
	ddp_closed() {
		// console.log('%c😢 Chat box disconnected', 'color: #c13030');
		this.btn.classList.add('unavailable');
		this.close_box();
	}

	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind() {
		if (this.rectangle) {
			this.rectangle.onclick = (_) => this.open_box();
		}
		window.on('focus', (_) => this.im_here());
		window.on('blur', (_) => (this.tab_focused = false));
		this.btn.onclick = (_) => this.toggle_box();
		this.textarea.onkeydown = (e) => this.submit_msg(e);
		this.textarea_btn.onclick = (_) => this.submit_msg();
		this.ddp.on('connected', (_) => this.ddp_connected());
		this.ddp.on('socket_close', (_) => this.ddp_closed());
		this.ddp.on(
			'added',
			(data) => data.collection === 'chat' && this.get_chat_bdd(data)
		);
	}

	/*--------------------------------------------------------------
	-	Bell
	--------------------------------------------------------------*/
	ring_my_bell() {
		if (this.robot_step === 'ended') {
			this.btn.classList.remove('new');
			return;
		}
		if (!this.tab_focused) {
			document.title = '🔔 ' + this.page_title;
			this.ding.play();
		}
		if (this.closed) {
			this.btn.classList.add('new');
		}
	}
	im_here() {
		this.tab_focused = true;
		document.title = this.page_title;
		if (!this.closed) {
			setTimeout((_) => this.textarea.focus(), 100);
		}
	}

	/*--------------------------------------------------------------
	-	Scroll down
	--------------------------------------------------------------*/
	scroll() {
		this.messages_list.scrollTop = this.messages_list.scrollHeight;
	}

	/*--------------------------------------------------------------
	-	Get Chat BDD
	--------------------------------------------------------------*/
	get_chat_bdd({ fields }) {
		// get the robot step
		if (fields.step) {
			this.robot_step = fields.step;
		}

		// get messages (user or admin)
		else {
			this.messages_list.appendChild(this.create_message(fields));
			this.scroll();
			this.user_data = fields.infos;

			// if an admin has answered
			if (!fields.isFromClient && !fields.isFromRobot && !this.admin_here) {
				this.admin_here = true;
				this.send_recap_chat();
				this.change_step('in_comm');
			}
		}
	}

	/*--------------------------------------------------------------
	-	Toggle Chat box
	--------------------------------------------------------------*/
	toggle_box() {
		if (this.box.classList.contains('open')) {
			this.close_box();
		} else {
			this.open_box();
		}
	}
	open_box() {
		this.closed = false;
		this.box.classList.add('open');
		this.btn.classList.add('on');
		this.btn.classList.remove('new');
		setTimeout((_) => this.textarea.focus(), 100);
		setTimeout((_) => this.scroll(), 300);

		// If admin not here
		if (!this.admin_here) {
			// Start robot if chat is empty
			this.user_area.classList.add('special');
			if (this.messages_list.children.length === 0) {
				setTimeout((_) => this.robot_start(), this.robotDelay);
			}
			// Continue robot
			else {
				this.robot_continue();
			}
		} else {
			this.user_area.classList.remove('special');
		}
	}
	close_box() {
		this.closed = true;
		this.box.classList.remove('open');
		this.btn.classList.remove('on');
	}

	/*--------------------------------------------------------------
	-	Create Chat box
	--------------------------------------------------------------*/
	create_chat_box() {
		// container
		this.box = document.createElement('div');
		this.box.id = 'chat_box';

		// messages list
		this.messages_list = document.createElement('div');
		this.messages_list.classList.add('messages');
		this.box.appendChild(this.messages_list);

		// user area (where all inputs & textareas go)
		this.user_area = document.createElement('div');
		this.user_area.classList.add('user_area');
		this.box.appendChild(this.user_area);

		// textarea
		this.textarea = document.createElement('div');
		this.textarea.classList.add('textarea');
		this.textarea.setAttribute('contenteditable', true);
		this.user_area.appendChild(this.textarea);
		this.textarea_btn = document.createElement('span');
		this.textarea_btn.classList.add('send');
		this.textarea_btn.title = 'Envoyer';
		this.user_area.appendChild(this.textarea_btn);

		// append to body
		document.body.insertBefore(this.box, document.querySelector('.main'));
	}

	/*--------------------------------------------------------------
	-	Create Message
	--------------------------------------------------------------*/
	create_message({ msg, isFromClient, isFromRobot, isAnError }) {
		// strip html
		let tmp = document.createElement('div');
		tmp.innerHTML = msg;
		msg = tmp.textContent || tmp.innerText || '';

		// put links into <a>
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		const text = msg.replace(
			urlRegex,
			'<a href="$1" target="_blank" rel="noopener">$1</a>'
		);

		const message = document.createElement('div');
		message.innerHTML = text;
		message.classList.add('message');
		if (isFromClient) {
			message.classList.add('client');
		}
		if (isFromRobot) {
			message.classList.add('robot');
		}
		if (isAnError) {
			message.classList.add('error');
		}

		// bell
		this.ring_my_bell();

		return message;
	}

	/*--------------------------------------------------------------
	-	Submit Message
	--------------------------------------------------------------*/
	submit_msg(e) {
		const answer = this.textarea.textContent;
		if (answer === '') {
			return;
		}
		if (e) {
			const key = e.keyCode || e.which;
			if (key === 13 && !e.shiftKey) {
				e.preventDefault();
				this.client_says(answer);
				this.textarea.textContent = '';
			}
		} else {
			this.client_says(answer);
			this.textarea.textContent = '';
		}
	}

	/*--------------------------------------------------------------
	-	Robot
	--------------------------------------------------------------*/
	robot_start() {
		this.robot_says(
			'Bonjour et bienvenue chez SCAMAC-IMMO. Veuillez saisir votre email.'
		);
		this.change_step('ask_for_email');
	}
	robot_continue() {
		this[this.robot_step]();
	}
	clean_user_area() {
		let i = this.user_area.children.length;
		while (i--) {
			let child = this.user_area.children[i];
			if (child !== this.textarea && child !== this.textarea_btn) {
				this.user_area.removeChild(child);
			}
		}
	}
	ask_for_email() {
		this.clean_user_area();

		// check
		const check_email = (email) => {
			return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		};

		// input
		const email = document.createElement('input');
		email.type = 'email';
		email.placeholder = 'Votre email...';
		email.onkeydown = (e) => enterInput(e);
		this.user_area.appendChild(email);
		setTimeout((_) => email.focus(), 100);
		let send = document.createElement('span');
		send.classList.add('send');
		send.title = 'Envoyer';
		send.onclick = (_) => submit_email();
		this.user_area.appendChild(send);

		// enter in input
		function enterInput(e) {
			const key = e.keyCode || e.which;
			if (key === 13 && !e.shiftKey) {
				e.preventDefault();
				submit_email();
			}
		}

		// submit
		const submit_email = (_) => {
			if (!check_email(email.value)) {
				this.robot_says('Cette adresse est incorrecte.', true);
			} else {
				this.client_says(email.value, { email: email.value });
				setTimeout((_) => {
					this.change_step('ask_for_types');
					this.robot_says('Merci. Quel type de produit recherchez-vous ?');
				}, this.robotDelay);
			}
		};
	}
	ask_for_types() {
		this.clean_user_area();

		// Wrapper
		const wrapper = document.createElement('div');
		wrapper.classList.add('btns');

		// Btns
		const btns = [];
		const types = [
			{ id: 1, name: 'Bureaux' },
			{ id: 2, name: 'Local commercial' },
			{ id: 3, name: 'Entrepôts' },
			{ id: 4, name: 'Terrain nu' },
		];
		types.forEach(({ id, name }) => {
			const btn = document.createElement('button');
			btn.value = id;
			btn.textContent = name;
			btn.onclick = (_) => check_btn(btn);
			btns.push(btn);
			wrapper.appendChild(btn);
		});

		// Submit
		const submit = document.createElement('button');
		submit.textContent = 'ok';
		submit.classList.add('submit');
		wrapper.appendChild(submit);
		submit.onclick = (_) => {
			let selected = [];
			btns.forEach((btn) => {
				if (btn.classList.contains('on')) {
					selected.push(btn.textContent);
				}
			});
			let msg = selected.join(' / ');
			this.client_says(msg, { types: selected });
			setTimeout((_) => {
				this.change_step('ask_for_geo');
				this.robot_says(
					'Très bien. Dans quelle ville ou quel département cherchez-vous ?'
				);
			}, this.robotDelay);
		};

		// Check if at least one selected
		function check_btn(btn) {
			btn.classList.toggle('on');
			let a = 0;
			btns.forEach((btn) => btn.classList.contains('on') && a++);
			submit.disabled = a === 0;
		}
		this.user_area.appendChild(wrapper);
	}
	ask_for_geo() {
		this.clean_user_area();

		// input
		const geo = document.createElement('input');
		geo.className = 'autocomplete';
		geo.type = 'text';
		geo.placeholder = 'Ville, département ou région...';
		this.user_area.appendChild(geo);
		setTimeout((_) => geo.focus(), 100);
		setTimeout((_) => this.scroll(), 300);

		// autocomplete
		const callback = ({ val, postal_code }) => {
			this.client_says(val, { geo: val + ', ' + postal_code });
			setTimeout((_) => {
				this.change_step('ask_for_surface');
				this.robot_says("C'est noté. Quelle surface souhaitez-vous ?");
			}, this.robotDelay);
		};
		new Autocomplete(geo, callback, 'fixed', 3);
	}
	ask_for_surface() {
		this.clean_user_area();

		// min / max
		const min = document.createElement('input');
		min.type = 'number';
		min.min = 10;
		min.max = 999999;
		min.placeholder = 'Surface min (m²)';
		min.onkeydown = (e) => enterInput(e);
		const max = min.cloneNode(true);
		max.placeholder = 'Surface max (m²)';
		max.onkeydown = (e) => enterInput(e);
		this.user_area.appendChild(min);
		this.user_area.appendChild(max);
		setTimeout((_) => min.focus(), 100);
		setTimeout((_) => this.scroll(), 300);

		// Submit
		const submit = document.createElement('button');
		submit.textContent = 'ok';
		submit.classList.add('submit_min_max');
		submit.onclick = (_) => submit_min_max();
		this.user_area.appendChild(submit);

		// enter in input
		function enterInput(e) {
			const key = e.keyCode || e.which;
			if (key === 13 && !e.shiftKey) {
				e.preventDefault();
				submit_min_max();
			}
		}

		// submit
		const submit_min_max = (_) => {
			let min_v = parseInt(min.value);
			let max_v = parseInt(max.value);
			if (!min_v && !max_v) {
				this.robot_says('Merci de remplir au moins un des deux champs.', true);
			} else if (min_v && max_v && min_v > max_v) {
				this.robot_says(
					'La surface maximum ne peut pas être inférieure à la surface minimum.',
					true
				);
			} else {
				let msg = [];
				if (min_v) {
					msg.push(`Min : ${min_v.toLocaleString('fr-FR')} m²`);
				}
				if (max_v) {
					msg.push(`Max : ${max_v.toLocaleString('fr-FR')} m²`);
				}
				this.client_says(msg.join(' / '), {
					surface_min: min_v,
					surface_max: max_v,
				});
				setTimeout((_) => {
					this.change_step('waiting');
					this.robot_says(
						'Merci, vous allez bientôt être mis en relation avec un de nos conseillers'
					);
				}, this.robotDelay);
			}
		};
	}
	waiting() {
		this.clean_user_area();
		if (this.loader) {
			return;
		}
		setTimeout((_) => this.add_loader(), this.robotDelay);
		setTimeout((_) => {
			this.remove_loader();
			if (!this.admin_here) {
				this.change_step('ask_for_contact');
				this.robot_says(
					"Aucun conseiller n'est disponible actuellement. Souhaitez-vous être contacté ultérieurement ?"
				);
			}
		}, this.waitForAdmin);
	}
	ask_for_contact() {
		this.clean_user_area();

		// oui / non
		const oui = document.createElement('button');
		oui.classList.add('yep_nope');
		oui.textContent = 'oui';
		oui.onclick = (_) => submit_yn(true);
		const non = oui.cloneNode(true);
		non.onclick = (_) => submit_yn(false);
		non.textContent = 'non';
		this.user_area.appendChild(oui);
		this.user_area.appendChild(non);

		// submit
		const submit_yn = (yep) => {
			if (!yep) {
				this.client_says('non', { to_contact: false });
				this.send_recap_chat();
				setTimeout((_) => {
					this.change_step('ended');
					this.robot_says("C'est noté. Bonne journée !");
				}, this.robotDelay);
			} else {
				this.client_says('oui', { to_contact: true });
				setTimeout((_) => {
					this.change_step('ask_for_tel');
					this.robot_says(
						"Veuillez rentrer votre numéro de téléphone afin qu'un conseiller puisse vous contacter."
					);
				}, this.robotDelay);
			}
		};
	}
	ask_for_tel() {
		this.clean_user_area();

		// input
		const tel = document.createElement('input');
		tel.type = 'tel';
		tel.placeholder = 'Téléphone...';
		tel.onkeydown = (e) => enterInput(e);
		this.user_area.appendChild(tel);
		setTimeout((_) => tel.focus(), 100);
		setTimeout((_) => this.scroll(), 300);

		// enter in input
		function enterInput(e) {
			const key = e.keyCode || e.which;
			if (key === 13 && !e.shiftKey) {
				e.preventDefault();
				submit_tel();
			}
		}

		// submit
		const submit_tel = (_) => {
			let phone = tel.value.replace(/ /g, '');
			if (phone.length < 10) {
				this.robot_says(
					'Le numéro ne peut pas comporter moins de 10 chiffres.',
					true
				);
			} else if (phone.length > 20) {
				this.robot_says('Ce numéro est trop long.', true);
			} else {
				this.client_says(tel.value, { tel: tel.value });
				this.send_recap_chat();
				setTimeout((_) => {
					this.change_step('ended');
					this.robot_says(
						'Merci, un conseiller vous rappellera dès que possible !'
					);
				}, this.robotDelay);
			}
		};
	}
	ended() {
		this.clean_user_area();

		// close chat
		const close = document.createElement('button');
		close.classList.add('close_chat');
		close.textContent = 'Fermer le chat';
		close.onclick = (_) => this.close_box();
		this.user_area.appendChild(close);
	}
	in_comm() {
		this.remove_loader();
		this.clean_user_area();
		this.user_area.classList.remove('special');
	}
	send_recap_chat() {
		const query = `mutation (
			$email: String!
			$geo: String
			$types: [String]!
			$surface_min: Int
			$surface_max: Int
			$to_contact: Boolean
			$tel: String
			$commercial: String
		) {
			sendRecapChat (
				email: $email
				geo: $geo
				types: $types
				surface_min: $surface_min
				surface_max: $surface_max
				to_contact: $to_contact
				tel: $tel
				commercial: $commercial
			)
		}`;
		ajax.graphql({ query, variables: this.user_data }).then(({ errors }) => {
			if (errors) {
				let i = errors.length;
				while (i--) {
					console.error(errors[i]);
				}
			}
		});
	}

	/*--------------------------------------------------------------
	-	Loader
	--------------------------------------------------------------*/
	add_loader() {
		this.loader = document.createElement('div');
		this.loader.classList.add('loader');
		this.messages_list.appendChild(this.loader);
		this.scroll();
	}
	remove_loader() {
		if (this.loader) {
			this.messages_list.removeChild(this.loader);
			delete this.loader;
		}
	}
}
