class Recommandation {
	constructor(limit) {
		this.container = $$.classes('recommandation')[0];
		this.limit = limit;
		tmpl.preload(['reco_one']);
		this.init();
	}

	init() {
		this._get_random_lot().then(lots => {
			this._gen_recommandations(lots);
		});
	}

	_get_count() {
		return new Promise((resolve, reject) => {
			ajax
				.graphql({
					query: '{ countLots (filters: {synchro: {web: true}}) }',
				})
				.then(({ data: { countLots } }) => {
					resolve(countLots);
				}, reject);
		});
	}

	_get_random_lot() {
		return new Promise((resolve, reject) => {
			this._get_count().then(count => {
				let min = Math.ceil(0);
				let max = Math.floor(count - this.limit);
				let n = Math.floor(Math.random() * (max - min)) + min;

				let query = `query ($filters: filtersLots!, $limit: Int, $offset: Int) {
					lots: searchLots (filters: $filters, limit: $limit, offset: $offset) {
						_id
						url
						adresse {
							ville {
								_id
								nom
								code_postal
							}
						}
						general {
							annonce
							type
						}
						statut {
							a_louer
							a_vendre
							commerciaux {
								_id
								nom
								prenom
								portable
								photo {
									src
								}
							}
						}
						vente {
							prix
						}
						location {
							loyer_annuel {
								ht_hc
							}
						}
						superficie_totale {
							totale
							batiment
						}
						images {
							src
							src_square: src(size: SQUARE)
							alt
						}
					}
				}`;

				let variables = {
					limit: this.limit,
					offset: n,
					filters: {
						synchro: {
							web: true,
						},
					},
				};

				ajax.graphql({ query, variables }).then(({ data: { lots } }) => {
					resolve(lots);
				});
			});
		});
	}

	_gen_recommandations(lots) {
		lots.forEach(lot => {
			let cp = Math.floor(lot.adresse.ville.code_postal / 1000);

			let nom, tel;
			if (lot.statut.commerciaux.length > 0) {
				nom =
					lot.statut.commerciaux[0].nom +
					' ' +
					lot.statut.commerciaux[0].prenom;
				tel = lot.statut.commerciaux[0].portable;
			}

			let statut;
			if (lot.statut.a_vendre && lot.statut.a_louer) {
				statut = 'À VENDRE ET À LOUER';
			} else if (lot.statut.a_vendre) {
				statut = 'À VENDRE';
			} else if (lot.statut.a_louer) {
				statut = 'À LOUER';
			}

			let lotTmpl = tmpl.render('reco_one', {
				title: `${statut} : <strong>${lot.general.type}</strong>`,
				annonce: lot.general.annonce,
				prix_vente:
					lot.vente && lot.vente.prix
						? lot.vente.prix.toLocaleString('fr-FR') + ' €'
						: null,
				prix_location:
					lot.location &&
					lot.location.loyer_annuel &&
					lot.location.loyer_annuel.ht_hc
						? lot.location.loyer_annuel.ht_hc.toLocaleString('fr-FR') +
						  ' €<span> / an HT TC</span>'
						: null,
				link: lot.url,
				img_src: lot.images[0]
					? lot.images[0].src
					: '/img/recherche/defaut.jpg',
				img_alt: lot.general.type,
				surface: lot.superficie_totale.batiment.toLocaleString('fr-FR') + ' m²',
				localisation: lot.adresse.ville.nom + ' (' + cp + ')',
				nom: nom,
				tel: tel,
				ref: '<span>Réf</span> ' + lot.ref,
				photo: lot.statut.commerciaux[0].photo.src,
			});

			this.container.appendChild(lotTmpl);
		});
	}
}
