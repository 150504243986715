/*--------------------------------------------------------------
AUTOCOMPLETE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Change
- Empty
--------------------------------------------------------------*/



const DOWN  = 40;
const UP    = 38;
const ENTER = 13;

class Autocomplete {



	/*--------------------------------------------------------------
	Constructor
	--------------------------------------------------------------*/
	constructor (el, callback, pos = 'absolute', limit = 10) {
		this.input = el;
		this.callback = callback;
		this.list = null;
		this.show = false;
		this.pos = pos;
		this.limit = limit;
		this.li_height = 35;

		this._init();
	}

	_init () {
		this._initList();
		this._updatePos();
		this._keydownEvent();
		this._keyupEvent();
		this._clickOutside();
		this._focus();
		this._render();
	}

	_createElement (area, search) {
		if (area.code) {
			area.code_postal = area.code
		}

		let li = document.createElement('li');
		li.innerHTML = area.nom.toLowerCase();

		let span = document.createElement('span');
		span.textContent = ', '+area.code_postal;
		li.appendChild(span);

		li.innerHTML = li.innerHTML.replace(search, `<b>${search}</b>`);
		li.setAttribute('data-id', area._id);
		li.setAttribute('data-name', area.nom);
		li.setAttribute('data-postal', area.code_postal);
		li.setAttribute('data-type', area.code_postal.length > 2 ? 'ville' : 'departement');
		if (area.gps) {
			li.setAttribute('data-lat', area.gps.coordinates[0]);
			li.setAttribute('data-lng', area.gps.coordinates[1]);
		}

		this._click(li);
		this._hover(li);

		return li;
	}

	_keyupEvent () {
		this.input.on('keyup', e => {
			if (e.target.value.length < 1) {
				this.list.removeChildren();
				return;
			}
			else if (e.keyCode === DOWN || e.keyCode === UP) {
				return;
			}

			this._updatePos();
			let search = e.target.value.toLowerCase();
			this.totalLis = 0;

			this._getMatchingAreas(search).then(areas => {
				this.list.removeChildren();

				this.totalLis = 0;
				let frag = document.createDocumentFragment();

				let i = -1;
				let limit = Math.min(this.limit, areas.length);
				while (++i < limit) {
					this.totalLis++;
					frag.appendChild(
						this._createElement(areas[i], search)
					);
				}
				this.list.appendChild(frag);
			});

		});
	}

	_keydownEvent () {
		this.input.on('keydown', e => {
			if (e.keyCode === ENTER) {
				e.preventDefault();
				this._enter();
			}
			else if (e.keyCode === DOWN) {
				this._down();
			}
			else if (e.keyCode === UP) {
				this._up();
			}
		});
	}

	_down () {
		let active = this.list.get('.active');

		if (active) {
			if (active.nextSibling) {
				active.className = '';
				active.nextSibling.className = 'active';
			}
		}
		else {
			this.list.children[0].className = 'active';
		}
	}

	_up () {
		let active = this.list.get('.active');

		if (active) {
			if (active.previousSibling) {
				active.className = '';
				active.previousSibling.className = 'active';
			}
		}
	}

	_enter () {
		let active = this.list.get('.active');

		if (active) {
			this._submit(active);
			this.input.value = '';
		}
	}

	_hover (li) {
		li.on('mouseover', e => {
			this.list.getAll('li').forEach(_ => _.className = '');
			li.className = 'active';
		});
	}

	_click (li) {
		li.on('click', e => {
			this.list.removeChildren();
			this.input.value = '';
			this._submit(li);
		});
	}

	_clickOutside () {
		document.body.on('click', e => {
			if (!this.list.contains(e.target) && e.target !== this.input) {
				this.show = false;
			}
			else {
				this.show = true;
			}
		});
	}

	_focus () {
		this.input.onfocus = _ => this.show = true;
	}

	_updatePos () {
		if (!this.totalLis) {
			return;
		}
		let pos = this.input.getBoundingClientRect();
		let top = pos.top + pos.height;
		let total_height = this.li_height * this.totalLis;

		if (total_height + top > window.innerHeight) {
			top = pos.top - total_height;
		}

		if (this.pos == 'absolute') {
			top += window.pageYOffset;
		}

		this.list.style.top = top + 'px';
		this.list.style.width = pos.width + 'px';
		this.list.style.left = pos.left + 'px';
	}

	_initList () {
		this.list = document.createElement('ul');
		this.list.className = 'autocomplete_list';
		this.list.style.position = this.pos;

		document.body.appendChild(this.list);
	}

	_render () {
		if ((this.input.offsetParent === null || !this.show) && this.list.style.display != 'none') {
			this.list.style.display = 'none';
		}
		else if (this.input.offsetParent != null && this.show && this.list.style.display == 'none') {
			this.list.style.display = 'inline-block';
		}
		this._updatePos();

		requestAnimationFrame(_ => {
			this._render();
		});
	}

	_submit (el) {
		this.callback({
			_id: el.getAttribute('data-id'),
			val: el.getAttribute('data-name'),
			code_postal: el.getAttribute('data-postal'),
			lat: el.getAttribute('data-lat') || null,
			lng: el.getAttribute('data-lng') || null
		});
	}

	/*--------------------------------------------------------------
	-	get Matching area from server
	--------------------------------------------------------------*/
	_getMatchingAreas (name) {
		return new Promise((resolve, reject) => {

			let query = `query ($search: String!) {
				autocompleteGeo (search: $search, villes: true, departements: true) {
					... on Ville {
						_id
						nom
						code_postal
						gps {
							type
							coordinates
						}
					}
					... on Departement {
						_id
						nom
						code
					}
				}
			}`;
			let variables = {search: name};

			ajax.graphql({
				query,
				variables
			}).then(data => {
				resolve(data.data.autocompleteGeo);
			}, reject);
		});
	}
}
