/*--------------------------------------------------------------
CONTACT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Submit
- Info
--------------------------------------------------------------*/

class ContactForm {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor(id) {
		this.form = $$.id(id);
		this.infoP = this.form.get('.info');
		this.button = this.form.get('button[type=submit]');
		this.pending = false;
		this.query = `
		mutation ($from: [String]!, $to: String!, $subject: String!, $message: String!) {
			sent: sendMessage (from: $from, to: $to, subject: $subject, message: $message)
		}`;
		this.form.onsubmit = e => this.submit(e);
	}

	/*--------------------------------------------------------------
	-	Submit
	--------------------------------------------------------------*/
	submit(e) {
		e.preventDefault();
		if (this.pending) return;
		this.button.disabled = true;
		this.infoP.className = 'info';
		this.info('Envoi en cours...', 'pending');
		let {
			email,
			firstname,
			lastname,
			phone,
			society,
			message,
			subject,
		} = new Form(this.form).parse();
		subject = 'Message en provenance du site SCAMAC-IMMO - ' + subject;
		let body = `Prénom : ${firstname}\n`;
		body += `Nom : ${lastname}\n`;
		body += `Email : ${email}\n`;
		body += `Téléphone : ${phone}\n`;
		if (society) {
			body += `Société : ${society}\n`;
		}
		body += `\nCorps du message :\n\n${message}\n\n\n\n`;
		body += `Ce message a été envoyé depuis la page contact du site de SCAMAC-IMMO.`;
		let variables = {
			to: '0',
			from: [`${firstname} ${lastname}`, email],
			subject,
			message: body,
		};
		ajax.graphql({ query: this.query, variables }).then(
			res => {
				this.pending = false;
				this.button.disabled = false;
				if (res.errors) {
					console.error(res.errors);
					this.info(
						'Une erreur est survenue. Veuillez réessayer ultérieurement.',
						'failed'
					);
				} else {
					this.form.reset();
					this.info('Merci, votre message a bien été envoyé.', 'done');
					gtag('event', 'conversion', {'send_to': 'AW-861511507/Zrh2CIDjhG4Q077mmgM'});
					dataLayer.push({ event: 'contact-form-sent' });
					// window.location.href = "/merci";

					// Bing
					window.uetq = window.uetq || [];
					window.uetq.push('event', 'boutton', {
						event_category: 'clic',
						event_label: 'contactok',
						event_value: '1',
					});
				}
			},
			err => {
				console.error(err);
				this.pending = false;
				this.button.disabled = false;
				this.info(
					'Une erreur est survenue. Veuillez réessayer ultérieurement.',
					'failed'
				);
			}
		);
	}

	/*--------------------------------------------------------------
	-	Info
	--------------------------------------------------------------*/
	info(message, classe) {
		this.infoP.textContent = message;
		this.infoP.classList.add(classe);
	}
}
