/*--------------------------------------------------------------
DELETE ALERT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Parse url
- Fetch Alert
- Delete Alert
--------------------------------------------------------------*/

class DeleteAlert {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		this.fetch_query = `query ($id: ID!) {
			alerteInternaute (id: $id) {
				title
			}
		}`;
		this.delete_query = `mutation ($id: ID!) {
			deleteAlerte (id: $id)
		}`;
		let { delete_alert } = this.parse();
		if (delete_alert) {
			this.alert_id = delete_alert;
			this.fetch_alert();
		}
	}

	/*--------------------------------------------------------------
	-	Parse url
	--------------------------------------------------------------*/
	parse() {
		// get params from url
		let params = {};
		let url_params = window.location.search.substring(1).split('&');
		url_params.forEach(param => {
			let [key, val] = param.split('=');
			params[key] = val;
		});

		// remove params from url
		window.history.replaceState(null, null, location.pathname);

		return params;
	}

	/*--------------------------------------------------------------
	-	Fetch Alert
	--------------------------------------------------------------*/
	fetch_alert() {
		ajax
			.graphql({ query: this.fetch_query, variables: { id: this.alert_id } })
			.then(({ data, errors }) => {
				if (errors) {
					errors.forEach(err => console.error('graphql: ' + err.message));
					popup.open(
						`<p>Cette alerte n'existe pas ou a déjà été supprimée.</p>`
					);
				} else {
					popup.open(`
					<p>Voulez-vous vraiment supprimer votre alerte "${data.alerteInternaute.title}"&nbsp;?</p>
					<button class="btn no" onclick="popup.close()">Non</button>
					<button class="btn" onclick="page.delete_alert.delete()">Oui</button>
				`);
				}
			});
	}

	/*--------------------------------------------------------------
	-	Delete Alert
	--------------------------------------------------------------*/
	delete() {
		ajax
			.graphql({ query: this.delete_query, variables: { id: this.alert_id } })
			.then(_ => {
				popup.open(`<p>Votre alerte a bien été supprimée</p>`);
			});
	}
}
