/*--------------------------------------------------------------
GRAPHQL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Contructor
- Public methods
--------------------------------------------------------------*/

class Search_result {
	/*--------------------------------------------------------------
	-	Contructor
	--------------------------------------------------------------*/
	constructor() {
		this.cookies = {};
		this.wrap = $$.id('search_result');
		this.zone = new Zone_perso(this.wrap);
		this.zone.onupdate((_) => {
			this.update_count();
		});

		this.fieldsets = this.wrap.classes('tab');
		this.total = $$.classes('countlots');
		this.tabs = this.wrap.getAll('.tabs input');
		this.form = new Form(this.wrap);
		this.btns_projets = $$.all('.checkbox_projet');

		// Liste de resultats
		this.ul = $$.id('results');
		this.spinner = $$.id('spinner');

		this.toggleSpinner = (show) => {
			this.spinner.style.display = show ? "block" : "none";
		};

		// Gen
		this.templates = ['model_lot'];

		// autocomplete
		this.place = this.wrap.classes('autocomplete')[0];
		this.distance = this.wrap.classes('distance')[0];

		// pagination
		this.paginations = $$.classes('pagination');
		this.page = this.wrap.page;

		// alerte
		this.modal = $$.id('myAlerte');
		this.selec = $$.id('selec');
		this.button = $$.id('send');

		// h1 recap de recherche
		this.recap = {};

		// Lots animation
		this.old_children = [];
		this.new_children = [];

		// place pills
		this.places = $$.id('cities');

		// ORDER BY
		this.order_by = {
			field: 'DATE',
			order: 'DESC',
		};

		// Events
		this.events = [
			{
				// Form submit
				listener: 'submit',
				target: this.wrap,
				handler: (e) => {
					e.preventDefault();
					this.update();
				},
			},
			{
				// Touche Echap ferme le panel de recherche
				listener: 'keyup',
				target: document.body,
				handler: (e) => {
					if (e.keyCode == 27) {
						this.fieldsets.forEach((f) => f.classList.remove('on'));
						this.tabs.forEach((t) => (t.checked = false));
					}
				},
			},
			{
				// Valider pour fermer
				listener: 'click',
				targets: this.total,
				handler: (_) => {
					this.fieldsets.forEach((f) => f.classList.remove('on'));
					this.tabs.forEach((t) => (t.checked = false));
				},
			},
			{
				// Mise à jour du contenu des tabs en fonction de la recherche
				listeners: ['input', 'change'],
				targets: this.wrap.getAll('.tab:not(:last-of-type) input, .distance'),
				handler: ({ target: _ }) => {
					let id = _.getAttribute('data-id');

					if (!id && _.id) {
						let label = $$.first(`label[for=${_.id}]`);
						if (label) {
							id = label.getAttribute('data-id');
							this._update_fields(id);
						}
					} else if (id) {
						this._update_fields(id);
					}

					this._update_url();
					this.update_count();
				},
			},
			{
				// empêcher de ne choisir aucun projet
				listener: 'click',
				targets: this.btns_projets,
				handler: (e) => {
					let nothing_checked = true;
					let i = this.btns_projets.length;
					while (i--) {
						if (this.btns_projets[i].checked) {
							nothing_checked = false;
						}
					}
					if (nothing_checked) {
						e.preventDefault();
					}
				},
			},
			{
				// ouverture popup message
				listener: 'click',
				target: $$.first('.cloche .alerte'),
				handler: (_) => alerte.open(),
			},
			{
				// click sur les paginations
				listener: 'click',
				targets: this.paginations,
				handler: (_) => {
					if (_.target.localName == 'a') {
						_.preventDefault();
						this.page.value = _.target.textContent;
						this.wrap.classes('submit')[0].click();
						return false;
					}
				},
			},
			{
				// pills recherche villes multiple => delete
				listener: 'click',
				targets: $$.all('#cities .delete'),
				handler: (_) => {
					_.target.parentNode.delete();
					this._check_radius();
					this._update_url();
					this.update_count();
					this._update_loc_field();
				},
			},
			{
				// ouverture module de recherche mobile
				listener: 'click',
				target: $$.classes('bouton-mobile-search')[0],
				handler: (_) => {
					this.wrap.style.display = 'inline-block';
				},
			},
			{
				// fermeture du module de recherche mobile
				listener: 'click',
				target: this.wrap.get('#mobile-search-close button'),
				handler: (_) => {
					_.preventDefault();
					this.wrap.style.display = 'none';
				},
			},
			{
				// order by
				listener: 'click',
				targets: $$.all('#filters button[data-field]'),
				handler: (e) => {
					let data_order = e.target.getAttribute('data-field');

					if (data_order) {
						if (
							data_order == 'DATE' ||
							data_order == 'SURFACE' ||
							data_order == 'PRIX'
						) {
							if (this.order_by.field != data_order) {
								this.order_by.field = data_order;
								e.target.parentNode.childNodes.forEach(
									(el) => (el.className = '')
								);
								this.order_by.order = e.target.className = 'DESC';
							} else {
								if (this.order_by.order == 'ASC') {
									this.order_by.order = e.target.className = 'DESC';
								} else {
									this.order_by.order = e.target.className = 'ASC';
								}
							}
							this.update();
						}
					}
				},
			},
		];
		this._init();
	}

	_init() {
		window.exportLot = this.download_lot_pdf;

		this._get_params();
		this._init_search_terms();
		this._bind_tabs();
		this.update_count();
		this._get_templates(this.templates);
		this._get_lots().then(({ data: { searchLots } }) => {
			this._update_pagination();
			this._gen(searchLots);
			this._show_lots();
		});
		this._update_recap();
		this._set_events();
	}

	_init_search_terms() {
		// recupere les informations de l-url pour remplir le formulaire
		if (this.params.perso) {
			this.zone.addPaths(JSON.parse(decodeURIComponent(this.params.perso)));
			delete this.params.perso;
		}

		if (this.params['more']) {
			delete this.params['more'];
			this._update_url();

			this.fieldsets[1].classList.add('on');
			this.tabs[1].checked = true;
			this.fieldsets[1].tags('label').forEach((el) => {
				el.classList.remove('off');
				el.classList.add('on');
			});
			$$.id('more_criteria').delete();
		}

		delete this.params['tab'];
		delete this.params['place'];

		if (this.params.gps) {
			let gps = JSON.parse(decodeURIComponent(this.params.gps));
			delete this.params.gps;

			gps.forEach((_) => this._add_place_pill(_));

			this._check_radius();
		}

		if (Object.keys(this.params).length > 0) {
			this.form.unSerialize(this.params);
		}

		this.wrap.getAll('.tabs label').forEach((label) => {
			let id = label.getAttribute('data-id');
			if (id) this._update_fields(id);
		});
	}

	_set_events() {
		// set les events contenus dans this.events
		this.events.forEach((event, i) => {
			if (event.targets) {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.targets.forEach((el) => el.on(listener, event.handler)); // multiple targets & multiple listeners
					});
				} else {
					event.targets.forEach((el) => el.on(event.listener, event.handler)); // multiple targets & single listener
				}
			} else if (event.target) {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.target.on(listener, event.handler); // single target & multiple listeners
					});
				} else {
					event.target.on(event.listener, event.handler); // single target & single listener
				}
			} else {
				console.error(
					`cannot set event ${event.listener} on null => event[${i}]`
				);
				console.warn(event);
			}
		});
	}

	_unset_events() {
		// unset tout les events contenus dans this.events
		this.events.forEach((event) => {
			if (event.targets) {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.targets.forEach((el) => el.off(listener, event.handler)); // multiple targets & multiple listeners
					});
				} else {
					event.targets.forEach((el) => el.off(event.listener, event.handler)); // multiple targets & single listener
				}
			} else {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.target.off(listener, event.handler); // single target & multiple listeners
					});
				} else {
					event.target.off(event.listener, event.handler); // single target & single listener
				}
			}
		});
	}

	_update_fields(id) {
		// met a jour le texte de l-onglet en fonction de la recherche
		let elements = this.wrap.getAll(`.tab *[data-id=${id}]`);
		let isLabel = elements[0] instanceof HTMLLabelElement;
		let value = [];

		let i = -1;
		while (++i < elements.length) {
			let el = elements[i];

			if (isLabel && $$.id(el.htmlFor).checked) {
				value.push(el.textContent);
			} else if (!isLabel && el.value.length > 0) {
				let tmp = parseInt(el.value);
				if (!isNaN(tmp) && el.nextSibling) {
					value.push(tmp.toLocaleString('fr-FR') + el.nextSibling.textContent);
				} else if (el.nextSibling) {
					value.push(el.value + el.nextSibling.textContent);
				} else {
					value.push(el.value);
				}
			}
		}

		if ((value = value.join(isLabel ? ', ' : ' - ')).length == 0) {
			let label = this.wrap.get(`label[data-id=${id}]`);
			this.recap[id] = label.textContent = label.getAttribute('data-default');
		} else {
			this.recap[id] = this.wrap.get(`label[data-id=${id}]`).textContent =
				value;
		}
	}

	_update_recap() {
		// met à jour la phrase de recap de recherche

		let recap = [];
		let { types, surface_min, surface_max, prix_min, prix_max, gps, r } =
			this.params;
		let { projet, type, surface, budget, localisation } = this.recap;

		let urlMatchedTitlesStr = this._getCookyByKey('url_matched_titles');
		let urlMatchedTitles = null;
		const h1 = $$.id('search_recap');

		try {
			if (urlMatchedTitlesStr) {
				urlMatchedTitles = JSON.parse(urlMatchedTitlesStr);
			}
		} catch (err) {
			console.warn(err);
		}

		if (
			urlMatchedTitles &&
			urlMatchedTitles['URL'] &&
			urlMatchedTitles['Titre H1']
		) {
			h1.textContent = urlMatchedTitles['Titre H1'];
			return;
		}

		recap.push(projet);

		if (types) {
			recap.push(type);
		}

		surface_min = surface_min ? parseInt(surface_min).toLocaleString() : null;
		surface_max = surface_max ? parseInt(surface_max).toLocaleString() : null;
		if (surface_min && surface_max) {
			recap.push(`de ${surface_min} m² à ${surface_max} m²`);
		} else if (surface_min) {
			recap.push(`${surface_min} m² min`);
		} else if (surface_max) {
			recap.push(`${surface_max} m² max`);
		}

		prix_min = prix_min ? parseInt(prix_min).toLocaleString() : null;
		prix_max = prix_max ? parseInt(prix_max).toLocaleString() : null;
		if (prix_min && prix_max) {
			recap.push(`de ${prix_min} € à ${prix_max} €`);
		} else if (prix_min) {
			recap.push(`${prix_min} € min`);
		} else if (prix_max) {
			recap.push(`${prix_max} € max`);
		}

		if (gps) {
			let villes = JSON.parse(decodeURIComponent(gps));
			if (villes.length > 0) {
				let locs = [];
				villes.forEach(({ val, postal_code }) => {
					if (!!val && locs.indexOf(val) === -1) {
						locs.push(val);
					} else if (locs.indexOf(postal_code) === -1) {
						locs.push(postal_code);
					}
				});
				if (r && villes.length === 1) {
					locs.push(`dans un rayon de ${r} km`);
				}
				recap.push(locs.join(', '));
			}
		}

		h1.textContent = recap.join(' / ');
	}

	_update_pagination() {
		// met à jour la pagination
		if (!this.count_lots) return;

		let max = Math.ceil(this.count_lots / 10);
		let i = -1;
		let content = document.createDocumentFragment();
		let cur_page = parseInt(this.params.page || 1);
		let show = [
			cur_page - 2,
			cur_page - 1,
			cur_page,
			cur_page + 1,
			cur_page + 2,
		];
		let l = show.length;

		if (max > 1) {
			let li = this.__gen_page_number(1);
			li.className = 1 == cur_page ? 'active' : '';
			content.appendChild(li);

			if (show.indexOf(2) == -1) {
				let sep = document.createElement('li');
				sep.textContent = '...';
				content.appendChild(sep);
			}

			while (++i < l) {
				if (show[i] > 1 && show[i] < max) {
					li = this.__gen_page_number(show[i]);
					li.className = show[i] == cur_page ? 'active' : '';
					content.appendChild(li);
				}
			}

			if (show.indexOf(max - 1) == -1) {
				let sep = document.createElement('li');
				sep.textContent = '...';
				content.appendChild(sep);
			}
			if (max > 1) {
				li = this.__gen_page_number(max);
				li.className = max == cur_page ? 'active' : '';
				content.appendChild(li);
			}
		}

		this.paginations.forEach((el) => {
			let ul = el.querySelector('ul');
			ul.removeChildren();
			ul.appendChild(content.cloneNode(true));
		});
	}

	_update_url() {
		// met à jour l-url en fonction des recherches effectuées
		let params = this.form.serialize();
		let locs = [];

		this.places.childNodes.forEach((el) => {
			locs.push({
				val: el.textContent,
				lat: el.getAttribute('data-lat'),
				lng: el.getAttribute('data-lng'),
				code_postal: el.getAttribute('data-postal'),
				_id: el.getAttribute('data-id'),
			});
		});

		params +=
			(params.length > 0 ? '&' : '') +
			'gps=' +
			encodeURIComponent(JSON.stringify(locs));

		history.pushState(null, '', '/recherche?' + params);
	}

	_get_params() {
		// recupère et parse les parametres de l-url pour la recherche
		this.params = {};
		const url_params = location.search.substr(1);
		if (url_params) {
			url_params.split('&').forEach((p) => {
				p = p.split('=');
				this.params[p[0]] = p[1];
			});
		}

		this._mergeParams(this.params, this._get_params_segments());
		if (!!this.params.t === false) {
			//this.params.t = 'louer';
			this.params.t = null;
		}
	}

	_get_params_segments() {
		// recupère et parse les segments de l-url pour la recherche
		let strCookies = document.cookie.split(';');
		let p = {};
		strCookies.forEach((strCookie) => {
			let kv = strCookie.split('=');
			p[decodeURIComponent(kv[0]).trim()] = decodeURIComponent(kv[1]).trim();
		});

		try {
			return JSON.parse(p['url_params']);
		} catch (err) {
			console.warn(err);
		}

		return {};
	}

	_getCookyByKey(key) {
		if (this.cookies[key]) {
			return this.cookies[key];
		}

		let strCookies = document.cookie.split(';');
		let p = {};
		strCookies.forEach((strCookie) => {
			let kv = strCookie.split('=');
			let _k = decodeURIComponent(kv[0]).trim();
			if (!this.cookies[_k]) {
				this.cookies[_k] = decodeURIComponent(kv[1]).trim();
			}
		});

		return this.cookies[key] || null;
	}

	/*_get_params_segments_old () { // recupère et parse les segments de l-url pour la recherche
		let segments = decodeURIComponent(window.location.pathname).split("/");
		let sales = segments[1].split("|");
		let locals = segments[2].split("|");
		let cities = segments[3].split("|");
	}*/

	_mergeParams(p1, p2) {
		p1.t = p1.t || p2.t;
		p1.types = p1.types || p2.types;
		p1.gps = p1.gps || p2.gps;

		if (p1.t && typeof p1.t === 'object') {
			p1.t = p1.t.join(',');
		}

		if (p1.types && typeof p1.types === 'object') {
			p1.types = p1.types.join(',');
		}

		if (p1.gps && typeof p1.gps === 'object') {
			p1.gps = JSON.stringify(p1.gps);
		}
	}

	_get_templates(tpls) {
		// recupere les templates de lot et de bien
		return tmpl.preload(this.templates);
	}

	_get_lots_filters() {
		// same as GraphQlQueries.js -> _paramToFilters
		let filters;
		if (this.params.ref) {
			filters = {
				general: {
					ref: decodeURIComponent(this.params.ref) || null,
				},
				synchro: {
					web: true,
				},
			};
		} else {
			let villes = [];
			let departements = [];
			if (this.params.gps) {
				let locs = JSON.parse(decodeURIComponent(this.params.gps));
				if (locs.length > 0) {
					villes = locs
						.filter((el) => el.code_postal.length > 2)
						.map((el) => el._id);
					departements = locs
						.filter((el) => el.code_postal.length == 2)
						.map((el) => el._id);
				}
			}

			let selectedTypes = null;
			if (this.params.types) {
				selectedTypes = this.params.types.split(',').map(
					(type) =>
						({
							1: 'Hangar',
							2: 'Entrepôt',
							3: 'Entrepôt frigorifique',
							4: 'Atelier',
							16: "Local d'activités",
							17: 'Local commercial',
							18: 'Bureau',
							19: 'Terrain',
							20: 'Locaux mixtes',
						}[type])
				);
			}

			filters = {
				adresse: {
					ville: villes.length > 0 ? villes : null,
					departement: departements.length > 0 ? departements : null,
				},
				general: {
					ref: this.params.ref || null,
					type: selectedTypes,
				},
				surfaces: {
					superficie_min: Number(this.params.surface_min) || null,
					superficie_max: Number(this.params.surface_max) || null,
				},
				synchro: {
					web: true,
				},
				polygons: this.zone.data.polygons || null,
				distance: villes.length === 1 && this.params.r ? Number(this.params.r) : null,
			};

			//  Budget
			if (
				['achat', 'louer,achat'].includes(this.params.t) &&
				(this.params.prix_max || this.params.prix_min)
			) {
				filters.vente = {};
				if (this.params.prix_max) {
					filters.vente.prix_max = this.params.prix_max;
				}
				if (this.params.prix_min) {
					filters.vente.prix_min = this.params.prix_min;
				}
			}

			if (
				['louer', 'louer,achat'].includes(this.params.t) &&
				(this.params.prix_max || this.params.prix_min)
			) {
				filters.location = {
					loyer_annuel: {},
				};
				if (this.params.prix_max) {
					filters.location.loyer_annuel.ht_hc_max = this.params.prix_max;
				}
				if (this.params.prix_min) {
					filters.location.loyer_annuel.ht_hc_min = this.params.prix_min;
				}
			}

			// a vendre / a louer
			const a_vendre = ['achat', 'louer,achat'].includes(this.params.t);
			const a_louer = ['louer', 'louer,achat'].includes(this.params.t);

			if (a_vendre && a_louer) {
				filters.statut = { a_vendre_ou_a_louer: true };
			} else if (a_vendre || a_louer) {
				filters.statut = {
					a_vendre: a_vendre || null,
					a_louer: a_louer || null,
				};
			}

			// zone perso
			if (filters.polygons && filters.polygons.length > 0) {
				filters.polygons = {
					type: 'MultiPolygon',
					coordinates: filters.polygons.map((polygon) => [
						[
							...polygon.map(({ lat, lng }) => [lat, lng]),
							[polygon[0].lat, polygon[0].lng],
						],
					]),
				};
			}
		}

		// filters.generated = true;

		return filters;
	}

	_get_lots() {
		// GRAPHQL recupere les biens / lots liés à la recherche
		let query = `
			query ($filters: filtersLots, $sort: SortLots, $limit: Int, $offset: Int) {
				searchLots(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
					_id
					url
					adresse {
						ville {
							nom
							code_postal
						}
					}
					general {
						ref
						annonce
						type
					}
					statut {
						a_vendre
						a_louer
						commerciaux {
							_id
							nom
							prenom
							portable
							email
							photo {
								src
							}
						}
					}
					adresse {
						ville {
							_id
							nom
						}
					}
					vente {
						prix
					}
					location {
						loyer_annuel {
							ht_hc
						}
					}
					superficie_totale {
						totale
						batiment
					}
					images {
						src
						alt
					}
				}
			}
		`;

		this._get_params();

		const filters = this._get_lots_filters();
		cookie.set('recherche', JSON.stringify(this.params), 2);

		let variables = {
			filters,
			offset: this.params.page * 10 - 10 || 0,
			limit: 10,
			sort: {
				field: this.order_by.field,
				order: this.order_by.order,
			},
		};

		return Promise.resolve(ajax.graphql({ query, variables }));
	}

	_gen(lots) {
		// lance la génération du contenu de la liste de resultats (biens / lots)
		this.new_children = [];
		let i = -1;
		while (++i < lots.length) {
			this.new_children.push(this._gen_lot(lots[i]).childNodes[0]);
		}
	}

	_gen_lot(data) {
		// génère un lot
		let cp = Math.floor(data.adresse.ville.code_postal / 1000);

		let nom, tel, commercial_id, commercial_mail;
		if (
			data.statut &&
			data.statut.commerciaux &&
			data.statut.commerciaux.length > 0
		) {
			nom =
				data.statut.commerciaux[0].prenom +
				' ' +
				data.statut.commerciaux[0].nom;
			tel = data.statut.commerciaux[0].portable;
			commercial_id = data.statut.commerciaux[0]._id;
			commercial_mail = data.statut.commerciaux[0].email;
		}

		let statut;
		if (data.statut.a_vendre && data.statut.a_louer) {
			statut = 'À VENDRE ET À LOUER';
		} else if (data.statut.a_vendre) {
			statut = 'À VENDRE';
		} else if (data.statut.a_louer) {
			statut = 'À LOUER';
		}

		let email = {
			subject: 'Ce bien peut vous intéresser - SCAMAC-IMMO',
			body: `${statut} : ${data.general.type}
			Lien vers le bien : https://www.scamac-immo.com${data.url}`,
		};

		let gmap_style = 'style=feature:all|element:labels|visibility:off';
		gmap_style += '&style=feature:landscape|element:all|color:0xf5f1e9';

		let lot = tmpl.render(this.templates[0], {
			projet_verbe: statut + ' : ',
			type: data.general.type,
			annonce: data.general.annonce,
			prix_vente:
				data.vente && data.vente.prix
					? data.vente.prix.toLocaleString('fr-FR') + ' €'
					: null,
			prix_location:
				data.location &&
				data.location.loyer_annuel &&
				data.location.loyer_annuel.ht_hc
					? data.location.loyer_annuel.ht_hc.toLocaleString('fr-FR') +
					  ' €<span> / an HT TC</span>'
					: null,
			link: data.url,
			img_src: data.images ? data.images[0].src : '/img/annonce-defaut.jpg',
			img_alt: data.general.type,
			surface:
				parseInt(data.superficie_totale.batiment) > 0
					? data.superficie_totale.batiment.toLocaleString('fr-FR') + ' m²'
					: null,
			localisation: data.adresse.ville.nom + ' (' + cp + ')',
			nom,
			tel,
			mail_commercial: `mailto:${encodeURI(commercial_mail)}?subject=&body=`,
			map: `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(
				data.adresse.ville
			)}&zoom=12&size=120x120&${encodeURI(gmap_style)}&key=${page.apiKey}`,
			ref: '<span>Réf</span> ' + data.general.ref,
			photo:
				data.statut &&
				data.statut.commerciaux &&
				data.statut.commerciaux[0].photo
					? data.statut.commerciaux[0].photo.src
					: null,
			share_facebook: `https://www.facebook.com/sharer/sharer.php?u=https://www.scamac-immo.com${encodeURI(
				data.url
			)}`,
			share_email: `mailto:?subject=${encodeURI(
				email.subject
			)}&body=${encodeURI(email.body)}`,
			pdf: `http${data.url}`,
			id:
				data.statut && data.statut.commerciaux
					? data.statut.commerciaux[0]._id
					: null,
			print: `exportLot('${data._id}')`,
		});

		return lot;
	}

	download_lot_pdf(idLot) {
		ajax
			.graphql({
				query: `query _($ids: [ID!]!) {
					pdf: pdfLotsByID(ids: $ids) {
						path
						files
						succes
						message
						queryIndex
					}
				}`,
				variables: { ids: [idLot] },
			})
			.then(({ data }) => {
				let a = document.createElement('a');
				a.setAttribute('href', encodeURI(data.pdf.path[0]));
				a.setAttribute('target', '_blank');
				a.setAttribute('download', data.pdf.files[0]);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
	}

	_download_commercial_vcf() {}

	__gen_page_number(n) {
		// génère un lien de pagination (1, 2, 3 ... 150)
		let li = document.createElement('li');
		let a = document.createElement('a');
		a.textContent = n;
		li.appendChild(a);
		return li;
	}

	_bind_tabs() {
		// ajout l-event onclick sur les onglets de recherche pour ouvrir le panel correspondant
		if (this.tabs && this.tabs.length > 0) {
			this.tabs.forEach((tab, i) => {
				tab.on('click', (_) => {
					if (window.innerWidth > 768) return;

					let fieldset = this.fieldsets[i];

					if (fieldset) {
						if (fieldset.classList.contains('on')) {
							fieldset.classList.remove('on');
							tab.checked = false;

							this.tabs.forEach((t) => (t.parentNode.style.height = ''));
						} else {
							this.fieldsets.forEach((f) => f.classList.remove('on'));
							fieldset.classList.add('on');
							tab.checked = true;

							this.tabs.forEach((t) => (t.parentNode.style.height = ''));

							let t_pos = tab.parentNode.getBoundingClientRect();
							let f_pos = fieldset.getBoundingClientRect();

							fieldset.style.top = t_pos.top + t_pos.height + 'px';

							tab.parentNode.style.height = t_pos.height + f_pos.height + 'px';
						}
					}
				});
				let label = tab.parentNode.get('label');
				label.on('mouseover', (_) => {
					if (window.innerWidth <= 768) return;

					let fieldset = this.fieldsets[i];

					if (fieldset && !fieldset.classList.contains('on')) {
						this.fieldsets.forEach((f) => f.classList.remove('on'));
						fieldset.classList.add('on');
						tab.checked = true;

						var toto = (e) => {
							if (
								!fieldset.contains(e.target) &&
								!_.target.parentNode.contains(e.target) &&
								!$$.first('.autocomplete_list').contains(e.target)
							) {
								window.off('mousemove', toto);
								fieldset.classList.remove('on');
								tab.checked = false;
							}
						};
						window.on('mousemove', toto);
					}
				});
			});
		}
	}

	_hide_lots() {
		// animation de dispariton des resultats de recherche
		this.old_children = this.ul.children;

		this.old_children.forEach((el, i) => {
			setTimeout(() => {
				el.classList.add('hide');
				el.classList.remove('show');
			}, i * 50);
		});
	}

	_show_lots() {
		// animation d-apparition des resultats de recherche
		let olds = this.ul.children;
		let i = olds.length;
		while (i--) {
			this.ul.removeChild(olds[i]);
		}
		this.new_children.forEach((el, i) => {
			this.ul.appendChild(el);
			setTimeout((_) => el.classList.add('show'), i * 50);
		});
		page.numero();
		window.scrollTo(0, 0);
		page.maitlo();
	}

	_add_place_pill(_) {
		// génère une pill de ville pour la recherche multi ville
		let div = document.createElement('div');
		div.className = 'city';
		div.textContent = _.val;

		let span = document.createElement('span');
		span.className = 'delete';

		span.on('click', (_) => {
			_.target.parentNode.delete();
			this._check_radius();
			this._update_url();
			this.update_count();
			this._update_loc_field();
		});

		div.appendChild(span);
		div.setAttribute('data-lat', _.lat);
		div.setAttribute('data-lng', _.lng);
		div.setAttribute('data-name', _.val);
		div.setAttribute('data-postal', _.code_postal);
		div.setAttribute('data-id', _._id);

		this.places.appendChild(div);
		this._update_loc_field();
	}

	_update_loc_field() {
		let tmp = '';
		this.places.childNodes.forEach((el, i, places) => {
			if (i == places.length - 1) {
				tmp += el.textContent;
			} else {
				tmp += el.textContent + ', ';
			}
		});
		this.wrap.get(`label[data-id=localisation]`).textContent =
			tmp || 'Localisation';
	}

	_check_radius() {
		// désactive le radius s-il y a plusieurs villes recherchées
		if (this.places.childNodes.length > 1) {
			this.distance.disabled = true;
			this.distance.parentNode.classList.add('disabled');
			this.distance.parentNode.style.display = 'none';
		} else if (
			this.places.childNodes.length === 1 &&
			this.places.childNodes[0].getAttribute('data-postal').length < 3
		) {
			this.distance.disabled = true;
			this.distance.parentNode.classList.add('disabled');
			this.distance.parentNode.style.display = 'none';
		} else {
			this.distance.disabled = false;
			this.distance.parentNode.classList.remove('disabled');
			this.distance.parentNode.style.display = 'inline-block';
		}
	}

	_get_polygon_points(polygon) {
		let n = [];
		let path = polygon.getPath();
		let i = path.getLength();
		while (i--) {
			n.push(path.getAt(i).toJSON());
		}
		return n;
	}

	_add_zone_pill() {
		let clean_polygons = [];

		this.zone.polygons.forEach((e) => {
			if (e) {
				clean_polygons.push(e.coords);
			}
		});

		if (clean_polygons.length > 0) {
			this.zone.style.display = 'none';

			let pol = $$.id('polygon');
			pol.style.display = 'block';
			pol.setAttribute('data-geo', JSON.stringify(clean_polygons));
			let place_inputs = this.wrap.getAll('.place input');
			place_inputs[0].disabled = true;
			place_inputs[1].parentNode.style.display = 'none';

			if (this.zone.els.pen.classList.contains('activated')) {
				this.zone.els.pen.classList.remove('activated');
				this.zone.drawingManager.setDrawingMode('');
			}
			if (this.zone.els.trash.classList.contains('activated')) {
				this.zone.els.trash.classList.remove('activated');
				this.zone.trash = false;
			}
			this.places.childNodes.forEach((e) => e.delete());
		}
	}

	/*--------------------------------------------------------------
	-	Public methods
	--------------------------------------------------------------*/
	update() {
		// met à jour le formulaire, l-url et les resultats de recherche en fonction du formulaire
		return new Promise((resolve, reject) => {
			this._update_url();
			this.page.value = 1;
			this._hide_lots();
			this.toggleSpinner(true);
			this._get_params();
			this._update_recap();
	
			const lotsPromise = this._get_lots();
	
			if (!lotsPromise || typeof lotsPromise.then !== 'function') {
				console.error("_get_lots() did not return a valid Promise.");
				this.toggleSpinner(false);
				return reject(new Error("_get_lots() did not return a valid Promise."));
			}
	
			lotsPromise
				.then(({ data: { searchLots } }) => {
					this._update_pagination();
					this._gen(searchLots);
					this._show_lots();
					this.toggleSpinner(false);
					resolve();
				})
				.catch((error) => {
					console.error("Error loading search results:", error);
					this.toggleSpinner(false);
					reject(error);
				});
		});
	}

	update_count() {
		debugger;
		// met à jour le nombre de resultats lié à la recherche
		this._get_params();

		let query = `
			query ($filters: filtersLots!) {
				countLots (filters: $filters)
			}
		`;

		let variables = {
			filters: this._get_lots_filters(),
		};

		ajax.graphql({ query, variables }).then(({ data: { countLots } }) => {
			this.count_lots = countLots;
			this._update_pagination();
			this.total.forEach((el) => {
				if (countLots === 1) {
					el.textContent = '1 annonce trouvée';
				} else {
					el.textContent = countLots + ' annonces trouvées';
				}
			});
		});
	}

	destroy() {
		this._unset_events();
	}

	autocomplete() {
		new Autocomplete(
			this.place,
			(_) => {
				if (_.val && _.val.length > 0) {
					let bool = true;

					let places = this.places.childNodes;
					let i = places.length;

					while (i--) {
						if (places[i].textContent == _.val) {
							bool = false;
						}
					}

					if (bool) {
						this._add_place_pill(_);
						this._check_radius();
					}

					this._update_url();
					this.update_count();
				}
			},
			'fixed'
		);
	}
}
