/*--------------------------------------------------------------
LOT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Recos
- Print
--------------------------------------------------------------*/

class Lot {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		this.similar_link = $$.first('.biens-similaires');
		// this.print();
		this.recos();
	}

	/*--------------------------------------------------------------
	-	Recos
	--------------------------------------------------------------*/
	recos() {
		const lot_id = $$.id('lot_id').value;
		const criteria = $$.id('criteria').value;
		const {
			a_vendre,
			a_louer,
			ville: { name: val, lat, lng, postal_code },
			surface_min,
			surface_max,
		} = criteria
			? JSON.parse(criteria)
			: {
					ville: {},
			  };

		// vignettes
		let vente = a_vendre && !a_louer;
		let location = a_louer && !a_vendre;
		tmpl.preload(['vignette']).then(_ => {
			vignettes.append('#biens-custom ul', {
				limit: 3,
				filters: {
					vente,
					location,
					villes: [postal_code],
					surface_min,
					surface_max,
					exclude: lot_id,
				},
			});
		});

		// url
		let params = [];

		let projet = [];
		if (a_louer) {
			projet.push('louer');
		}
		if (a_vendre) {
			projet.push('achat');
		}
		params.push('t=' + projet.join(','));

		params.push('surface_min=' + surface_min);
		params.push('surface_max=' + surface_max);

		let gps = [{ val, lat, lng, postal_code }];
		params.push('gps=' + encodeURIComponent(JSON.stringify(gps)));

		this.similar_link.href = '/recherche?' + params.join('&');
	}

	/*--------------------------------------------------------------
	-	Print
	--------------------------------------------------------------*/
	// print () {
	// 	let query = window.location.search.substring(1).split('&');
	// 	let params = {};
	// 	let i = query.length;
	// 	while (i--) {
	// 		let [key, value] = query[i].split("=");
	// 		params[key] = value;
	// 	}
	// 	if (params['print'] == 1) {
	// 		window.history.replaceState(null, null, location.pathname);
	// 		window.print();
	// 	}
	// }
}
