/*--------------------------------------------------------------
SIDEBAR  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
--------------------------------------------------------------*/




class Sidebar {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (id) {
		this.sidebar = $$.id(id);
		this.btn_alert = this.sidebar.get('.btn_alert');
		this.btn_phone = this.sidebar.get('.btn_phone');
		this.btn_chat = this.sidebar.get('.btn_chat');
		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.btn_alert.onclick = _ => {
			if ($$.id('recherche')) {
				alerte.open();
			} else {
				popup.open(`<p>
					Veuillez effectuer une recherche pour créer votre alerte.<br>
					<a class="btn" href="/">Faire une recherche</a>
				</p>`);
			}
		};
		this.btn_phone.onclick = _ => popup.open(`<p>
			<strong class="big">SCAMAC-IMMO</strong><br>
			<a class="btn" href="tel:+33160374040">+33(0)1 60 37 40 40</a>
		</p>`);
		// this.btn_chat.onclick = _ => popup.open(`<p>Le module de chat n'est pas encore disponible mais le sera bientôt.</p>`);
	}



}
