/*--------------------------------------------------------------
ADELIOS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- rAF
- $$
- Prototypes
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	rAF
--------------------------------------------------------------*/
const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.msCancelRequestAnimationFrame;



/*--------------------------------------------------------------
	$$
--------------------------------------------------------------*/
const $$ = {
	id: el => document.getElementById(el),
	first: el => document.body.querySelector(el),
	all: el => document.body.querySelectorAll(el),
	classes : el => document.body.getElementsByClassName(el),
	tags: el => document.getElementsByTagName(el)
};



/*--------------------------------------------------------------
	Prototypes
--------------------------------------------------------------*/
HTMLElement.prototype.transform = function (style) {
	this.style.transform = this.style.webkitTransform = style;
};
HTMLElement.prototype.tags = function (el) {
	return this.getElementsByTagName(el);
};
HTMLElement.prototype.classes = function (el) {
	return this.getElementsByClassName(el);
};
DocumentFragment.prototype.get = HTMLElement.prototype.get = function (el) {
	return this.querySelector(el);
};
DocumentFragment.prototype.getAll = HTMLElement.prototype.getAll = function (el) {
	return this.querySelectorAll(el);
};
HTMLElement.prototype.delete = function() {
	this.parentNode.removeChild(this);
};
HTMLElement.prototype.removeChildren = function () {
	while(this.firstChild) {
    this.removeChild(this.firstChild);
  }
  return this;
};
window.on = HTMLElement.prototype.on = function (e, func) {
	this.addEventListener(e, func);
};
window.off = HTMLElement.prototype.off = function (e, func) {
	this.removeEventListener(e, func);
};
HTMLCollection.prototype.forEach = NodeList.prototype.forEach = Array.prototype.forEach = function (fn) {
	var l = this.length;
	var i = -1;
	while (++i < l) {
		fn(this[i], i, this);
	}
};
