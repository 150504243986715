class Zone_perso {
	constructor(wrap) {
		this.wrap = wrap;
		this.els = {
			popup: $$.id('popup-zone'),
			btn: $$.id('zone-perso'),
			pill: $$.id('polygon'),
			pen: $$.id('zone-pen'),
			trash: $$.id('zone-trash'),
			more: $$.id('zone-zoom-more'),
			less: $$.id('zone-zoom-less'),
			submit: $$.id('zone-submit'),
		};
		this.data = {
			polygons: [],
			pol_id: 0,
			trash: false,
			selected: null,
			points: this._get_polygon_points,
		};
		this.cb = [];

		this.events = [
			{
				// ouverture zone perso
				listener: 'click',
				target: this.els.btn,
				handler: event => {
					this.els.popup.style.display = 'block';
					if (!this.data.map) {
						this._init_map();
					}
				},
			},
			{
				// ferneture de la zone perso
				listener: 'click',
				target: window,
				handler: event => {
					if (event.target == this.els.popup) {
						this.els.popup.style.display = 'none';
					}
				},
			},
			{
				// zone perso => click sur le stylo
				listener: 'click',
				target: this.els.pen,
				handler: e => {
					if (this.els.pen.classList.contains('activated')) {
						this.data.drawingManager.setDrawingMode('');
						this.els.pen.classList.remove('activated');
					} else {
						this.els.trash.classList.remove('activated');
						this.data.trash = false;

						this.data.drawingManager.setDrawingMode('polygon');
						this.els.pen.classList.add('activated');
					}
				},
			},
			{
				// zone perso => click sur la poubelle
				listener: 'click',
				target: this.els.trash,
				handler: e => {
					if (this.els.trash.classList.contains('activated')) {
						this.els.trash.classList.remove('activated');
						this.data.trash = false;
					} else {
						this.els.pen.classList.remove('activated');
						this.data.drawingManager.setDrawingMode('');

						this.els.trash.classList.add('activated');
						this.data.trash = true;
					}
				},
			},
			{
				// zone perso => click sur le bouton de dé-zoom
				listener: 'click',
				target: this.els.less,
				handler: e => {
					let $ = this.data;
					let zoom = $.map.getZoom();
					$.map.setZoom(zoom > 0 ? zoom - 1 : 0);
				},
			},
			{
				// zone perso => click sur le bouton de zoom
				listener: 'click',
				target: this.els.more,
				handler: e => {
					let $ = this.data;
					$.map.setZoom($.map.getZoom() + 1);
				},
			},
			{
				// zone perso => validation
				listener: 'click',
				target: this.els.submit,
				handler: e => {
					this._add_zone_pill();
					this._update_pill_position();
					this.update();
				},
			},
			{
				// suppression de la 'pill' "zone personnalisée"
				listener: 'click',
				target: this.els.pill.childNodes[1],
				handler: e => {
					e.target.parentNode.style.display = 'none';
					let place_inputs = this.wrap.getAll('.place input');
					place_inputs[0].disabled = false;
					place_inputs[1].parentNode.style.display = '';
					this.els.pill.removeAttribute('data-geo');
					// this.data.polygons = [];
					this.update();
				},
			},
			{
				// TEMP => mise a jour de la position de la 'pill' on resize
				listener: 'resize',
				target: window,
				handler: e => {
					this._update_pill_position();
				},
			},
			{
				// TEMP => mise a jour de la position de la 'pill' lors de l'ouverture de la 'tab'
				listener: 'mouseover',
				target: $$.first("label[data-id='localisation']"),
				handler: e => {
					setTimeout(_ => {
						this._update_pill_position();
					}, 50);
				},
			},
		];

		this.init();
	}

	init() {
		this._set_events();
	}

	_set_events() {
		this.events.forEach((event, i) => {
			if (event.targets) {
				if (event.listeners) {
					event.listeners.forEach(listener => {
						event.targets.forEach(el => el.on(listener, event.handler)); // multiple targets & multiple listeners
					});
				} else {
					event.targets.forEach(el => el.on(event.listener, event.handler)); // multiple targets & single listener
				}
			} else if (event.target) {
				if (event.listeners) {
					event.listeners.forEach(listener => {
						event.target.on(listener, event.handler); // single target & multiple listeners
					});
				} else {
					event.target.on(event.listener, event.handler); // single target & single listener
				}
			} else {
				// console.error(`cannot set event ${event.listener} on null => event[${i}]`);
				// console.warn(event)
			}
		});
	}

	_init_map() {
		let $ = this.data;
		let pos = { lat: 48.85613168160397, lng: 2.353099286556244 };
		$.map = new google.maps.Map(this.els.popup.classes('map')[0], {
			zoom: 12,
			center: pos,
			disableDefaultUI: true,
			mapTypeControl: true,
			mapTypeControlOptions: {
				mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
			},
		});
		$.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: '',
			drawingControl: false,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT,
				drawingModes: ['polygon'],
			},
			polygonOptions: {
				geodesic: true,
				draggable: true,
				editable: true,
				fillColor: '#00709f',
				fillOpacity: 0.17,
				strokeColor: '#0070a0',
				strokeWeight: 1,
			},
		});
		$.polygons.forEach(p => {
			let polygon = new google.maps.Polygon({
				paths: p,
				geodesic: true,
				draggable: true,
				editable: true,
				fillColor: '#00709f',
				fillOpacity: 0.17,
				strokeColor: '#0070a0',
				strokeWeight: 1,
			});

			polygon.pol_id = $.pol_id;
			$.pol_id++;

			polygon.addListener('mouseup', function() {
				if ($.trash) {
					this.setMap(null);
					delete $.polygons[this.pol_id];
				} else {
					$.polygons[this.pol_id] = $.points(this);
				}
			});

			polygon.setMap($.map);
		});

		$.drawingManager.addListener('polygoncomplete', polygon => {
			polygon.pol_id = $.pol_id;
			$.polygons[$.pol_id] = $.points(polygon);
			$.pol_id++;

			polygon.addListener('mouseup', function() {
				if ($.trash) {
					this.setMap(null);
					delete $.polygons[this.pol_id];
				} else {
					$.polygons[this.pol_id] = $.points(this);
				}
			});
		});

		$.drawingManager.setMap($.map);
	}

	_add_zone_pill() {
		let clean_polygons = [];

		this.data.polygons.forEach(e => {
			if (e) {
				clean_polygons.push(e);
			}
		});

		if (clean_polygons.length > 0) {
			this.els.popup.style.display = 'none';

			this.els.pill.style.display = 'block';
			this.els.pill.setAttribute('data-geo', JSON.stringify(clean_polygons));
			let place_inputs = this.wrap.getAll('.place input');
			place_inputs[0].disabled = true;
			place_inputs[1].parentNode.style.display = 'none';

			if (this.els.pen.classList.contains('activated')) {
				this.els.pen.classList.remove('activated');
				this.data.drawingManager.setDrawingMode('');
			}
			if (this.els.trash.classList.contains('activated')) {
				this.els.trash.classList.remove('activated');
				this.data.trash = false;
			}
			$$.id('cities').childNodes.forEach(e => e.delete());
		}
	}

	_get_polygon_points(polygon) {
		let n = [];
		let path = polygon.getPath();
		let i = path.getLength();
		while (i--) {
			n.push(path.getAt(i).toJSON());
		}
		return n;
	}

	addPaths(paths) {
		this.data.polygons = paths;
		if (this.data.polygons.length > 0) {
			this._add_zone_pill();
		}
	}

	_update_pill_position() {
		// temp => css to do
		let input = $$.classes('autocomplete')[0].getBoundingClientRect().left;
		let container = $$.classes('place')[0].getBoundingClientRect().left;
		this.els.pill.style.left = input - container + 'px';
	}

	onupdate(cb) {
		this.cb.push(cb);
	}

	update() {
		this.cb.forEach(fn => fn());
	}

	getPolygons() {
		if (this.els.pill.style.display == 'none') {
			return [];
		} else {
			return this.data.polygons;
		}
	}
}
