/*--------------------------------------------------------------
SEARCH HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Init
- Set events
- Pop up
--------------------------------------------------------------*/

class Search_home {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		this.wrap = $$.id('search_home');

		this.zone = new Zone_perso(this.wrap);
		this.zone.onupdate((_) => {
			this.update();
		});

		this.inputs = this.wrap.querySelectorAll(
			"input:not([class='autocomplete'])"
		);
		this.total = $$.classes('countlots');
		this.tabs = this.wrap.getAll('.tabs input');
		this.more = $$.id('more_criteria');
		this.form = new Form(this.wrap);
		this.places = $$.id('cities');
		this.numero = $$.classes('tel');
		this.distance = this.wrap.classes('distance')[0];

		// Plus de critères
		this.more = $$.id('plus-home-search');
		this.alert = $$.id('alert-home-mobile');
		this.pop = $$.id('alert');

		// Proprio
		this.proprio = this.wrap.get('.proprio button');
		this.popup_proprio = $$.first('.popup_proprio');

		// Events
		this.events = [
			{
				// submit form
				listener: 'submit',
				target: this.wrap,
				handler: (e) => {
					e.preventDefault();
					let params = this.form.serialize();

					let polygon =
						JSON.parse($$.id('polygon').getAttribute('data-geo')) || [];
					if (polygon.length > 0) {
						params +=
							(params.length > 0 ? '&' : '') +
							'perso=' +
							encodeURIComponent(JSON.stringify(polygon));
					} else {
						let locs = [];
						let cities = this.places.childNodes;

						cities.forEach((_) => {
							locs.push({
								lat: _.getAttribute('data-lat'),
								lng: _.getAttribute('data-lng'),
								code_postal: _.getAttribute('data-postal'),
								_id: _.getAttribute('data-id'),
								val: _.textContent,
							});
						});

						params +=
							(params.length > 0 ? '&' : '') +
							'gps=' +
							encodeURIComponent(JSON.stringify(locs));
					}

					location.href = '/recherche?' + params;
				},
			},
			{
				listeners: ['change', 'input'],
				targets: this.inputs,
				handler: (e) => this.update(),
			},

			{
				// ouverture popup message
				listener: 'click',
				target: this.alert,
				handler: (_) => {
					this.pop.classList.add('on');
					this.get_params();
					alerte.open_H();
				},
			},
			{
				listener: 'click',
				target: this.more,
				handler: (_) => {
					let tmp = this.wrap.getAll('.off');
					if (tmp.length > 0) {
						tmp.forEach((el) => {
							el.className = '';
						});
						_.target.style.display = 'none';
					}
				},
			},
		];

		this._init();
	}

	/*--------------------------------------------------------------
	-	Init
	--------------------------------------------------------------*/
	_init() {
		this._set_events();
		this.update();
	}

	/*--------------------------------------------------------------
	-	Set events
	--------------------------------------------------------------*/
	_set_events() {
		this.events.forEach((event, i) => {
			if (event.targets) {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.targets.forEach((el) => el.on(listener, event.handler)); // multiple targets & multiple listeners
					});
				} else {
					event.targets.forEach((el) => el.on(event.listener, event.handler)); // multiple targets & single listener
				}
			} else if (event.target) {
				if (event.listeners) {
					event.listeners.forEach((listener) => {
						event.target.on(listener, event.handler); // single target & multiple listeners
					});
				} else {
					event.target.on(event.listener, event.handler); // single target & single listener
				}
			} else {
				console.error(
					`cannot set event ${event.listener} on null => event[${i}]`
				);
				console.warn(event);
			}
		});
	}

	/*--------------------------------------------------------------
	-	Autocomplete
	--------------------------------------------------------------*/
	autocomplete() {
		new Autocomplete(this.wrap.get('.autocomplete'), (_) => {
			if (_.val && _.val.length > 0) {
				let bool = true;

				let places = this.places.childNodes;
				let i = places.length;

				while (i--) {
					if (places[i].textContent == _.val) {
						bool = false;
					}
				}

				if (bool) {
					this._add_place_pill(_);
					this._check_radius();
				}

				this.update();
			}
		});
	}

	/*--------------------------------------------------------------
	-	Update
	--------------------------------------------------------------*/
	update() {
		let params = this.form.parse();
		let filters;
		let query = `
		query ($filters: filtersLots!) {
			countLots (filters: $filters)
		}
		`;
		if (params.ref) {
			filters = {
				general: {
					ref: params.ref || null,
				},
				synchro: {
					web: true,
				},
			};
		} else {
			params.gps = [];
			let cities = this.places.childNodes;

			let polygons;
			if (this.zone.data.polygons.length > 0) {
				polygons = this.zone.data.polygons.map((polygon) => {
					polygon = polygon.map((coordinate) => {
						return [coordinate.lat, coordinate.lng];
					});
					polygon.push(polygon[0]);
					return [polygon];
				});
				polygons = { type: 'MultiPolygon', coordinates: polygons };
			}

			cities.forEach((_) => {
				params.gps.push({
					_id: _.getAttribute('data-id'),
					lat: _.getAttribute('data-lat'),
					lng: _.getAttribute('data-lng'),
					code_postal: _.getAttribute('data-postal'),
					_id: _.getAttribute('data-id'),
					val: _.getAttribute('data-name'),
				});
			});

			let villes = [];
			let departements = [];
			if (params.gps.length > 0) {
				villes = params.gps
					.filter((el) => el.code_postal.length > 2)
					.map((el) => el._id);
				departements = params.gps
					.filter((el) => el.code_postal.length == 2)
					.map((el) => el._id);
			}

			let selectedTypes = null;
			if (params.types) {
				selectedTypes = params.types.map(
					(type) =>
						({
							1: 'Hangar',
							2: 'Entrepôt',
							3: 'Entrepôt frigorifique',
							4: 'Atelier',
							16: "Local d'activités",
							17: 'Local commercial',
							18: 'Bureau',
							19: 'Terrain',
							20: 'Locaux mixtes',
						}[type])
				);
			}

			filters = {
				adresse: {
					ville: villes.length > 0 ? villes : null,
					departement: departements.length > 0 ? departements : null,
				},
				general: {
					type: selectedTypes,
				},
				statut: {
					a_vendre: ['achat', 'louer,achat'].includes(params.t) ? true : null,
					a_louer: ['louer', 'louer,achat'].includes(params.t) ? true : null,
				},
				surfaces: {
					superficie_min: Number(params.surface_min) || null,
					superficie_max: Number(params.surface_max) || null,
				},
				synchro: {
					web: true,
				},
				polygons: polygons || null,
				distance: villes.length === 1 && params.r ? Number(params.r) : null,
			};
		}

		ajax
			.graphql({
				query,
				variables: {
					filters,
				},
			})
			.then(({ data: { countLots } }) => {
				this.total.forEach((el) => {
					this.count_lots = countLots;
					el.textContent =
						countLots === 1
							? '1 annonce trouvée'
							: countLots + ' annonces trouvées';
				});
			});
	}

	/*--------------------------------------------------------------
	-	Others
	--------------------------------------------------------------*/
	_add_place_pill(_) {
		let div = document.createElement('div');
		div.className = 'city';
		div.textContent = _.val;

		let span = document.createElement('span');
		span.className = 'delete';

		span.on('click', (_) => {
			_.target.parentNode.delete();
			this._check_radius();
			this.update();
		});

		div.appendChild(span);
		div.setAttribute('data-lat', _.lat);
		div.setAttribute('data-lng', _.lng);
		div.setAttribute('data-name', _.val);
		div.setAttribute('data-postal', _.code_postal);
		div.setAttribute('data-id', _._id);

		this.places.appendChild(div);
	}

	_check_radius() {
		if (this.places.childNodes.length > 1) {
			this.distance.disabled = true;
			this.distance.parentNode.style.display = 'none';
		} else if (
			this.places.childNodes.length === 1 &&
			this.places.childNodes[0].getAttribute('data-postal').length < 3
		) {
			this.distance.disabled = true;
			this.distance.parentNode.style.display = 'none';
		} else {
			this.distance.disabled = false;
			this.distance.parentNode.style.display = 'inline-block';
		}
	}

	/*--------------------------------------------------------------
	-	Pop up
	--------------------------------------------------------------*/

	get_params() {
		this.params = this.form.parse();

		this.params.gps = [];
		let cities = this.places.childNodes;

		cities.forEach((_) => {
			this.params.gps.push({
				lat: _.getAttribute('data-lat'),
				lng: _.getAttribute('data-lng'),
				code_postal: _.getAttribute('data-postal'),
				_id: _.getAttribute('data-id'),
				val: _.textContent,
			});
		});

		this.variables = {
			ref: this.params.ref || null,
			vente: this.params.t == 'achat',
			location: this.params.t == 'louer',
			types_bien: this.params.types ? this.params.types : null,
			s_min: this.params.surface_min || null,
			s_max: this.params.surface_max || null,
			p_min: this.params.prix_min || null,
			p_max: this.params.prix_max || null,
			polygons:
				this.zone.getPolygons().length > 0 ? this.zone.getPolygons() : null,
		};

		if (this.params.gps) {
			let locs = this.params.gps;

			this.params.r = parseInt(this.params.r);
			if (this.params.r && this.params.r !== 0 && locs.length === 1) {
				this.variables.villes = null;

				this.variables.gps = {
					lat: locs[0].lat,
					lng: locs[0].lng,
					radius: this.params.r + 2,
				};
			} else if (locs.length > 0) {
				this.variables.gps = null;
				this.variables.villes = [];

				locs.forEach((_) => {
					this.variables.villes.push(_.val);
				});
			}
		}
	}
}
