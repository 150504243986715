/*--------------------------------------------------------------
ALERT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Open
- Add Filters
- Submit
- Done
- Reset
--------------------------------------------------------------*/

class Alert extends Popup {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor(id) {
		super(id);
		this.pending = false;
		this.query = `mutation ($title: String!, $email: String!, $tel: String!, $params: filtersLots!) {
			createAlert (title: $title, email: $email, tel: $tel, filters: $params) {
				_id
			}
		}`;
		this.form = this.popup.get('form');
		this.close_btn = this.form.get('.close');
		this.ul = this.form.get('ul');
		this.response = this.popup.get('.response');
		this.res_success = this.response.get('.success');
		this.res_failure = this.response.get('.failure');
		this.btn_ok = this.response.get('button');
		this.bind();
	}

	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind() {
		this.form.onsubmit = (e) => this.submit(e);
		this.btn_ok.onclick = (_) => this.close();
		this.close_btn.onclick = (_) => this.close();
	}

	/*--------------------------------------------------------------
	-	Open
	--------------------------------------------------------------*/
	open() {
		this.add_filters();
		this.reset();
		super.open();
	}

	open_H() {
		this.add_filtersH();
		this.reset();
		super.open();
	}

	/*--------------------------------------------------------------
	-	Add Filters
	--------------------------------------------------------------*/
	add_filters() {
		this.ul.removeChildren();
		let { types, surface_min, surface_max, prix_min, prix_max, gps, r } =
			page.sr.params;
		let { projet, type, surface, budget, localisation } = page.sr.recap;

		projet.split(', ').forEach((txt) => this.create_li(txt));

		if (types) {
			type.split(', ').forEach((txt) => this.create_li(txt));
		}

		if (surface_min) {
			this.create_li(parseInt(surface_min).toLocaleString() + ' m² min');
		}
		if (surface_max) {
			this.create_li(parseInt(surface_max).toLocaleString() + ' m² max');
		}

		if (prix_min) {
			this.create_li(parseInt(prix_min).toLocaleString() + ' € min');
		}
		if (prix_max) {
			this.create_li(parseInt(prix_max).toLocaleString() + ' € max');
		}

		if (gps) {
			let villes = JSON.parse(decodeURIComponent(gps));
			villes.forEach((ville) => this.create_li(ville.val));
			if (r && villes.length === 1) {
				this.create_li(`Rayon : ${r} km`);
			}
		}
	}
	add_filtersH() {
		this.ul.removeChildren();
		let dat = page.sh.params;
		let poly = page.sh.zone.getPolygons();

		Object.keys(dat).forEach((key) => {
			if (key === 'types') {
				dat[key].forEach((val, i) => {
					let tmp = $$.first(`input[value='${val}']`);

					let li = document.createElement('li');
					li.textContent = $$.first(`label[for='${tmp.id}']`).textContent;
					this.ul.appendChild(li);
				});
			} else if (key === 'gps') {
				dat[key].forEach((loc, i) => {
					let li = document.createElement('li');
					li.textContent = loc.val;
					this.ul.appendChild(li);
				});
			} else if (key === 'r') {
				if (dat['r'] && dat.gps && dat.gps.length === 1) {
					let li = document.createElement('li');
					li.textContent = `Rayon : ${dat['r']} km`;
					this.ul.appendChild(li);
				}
			} else {
				let li = document.createElement('li');
				li.textContent = dat[key];
				this.ul.appendChild(li);
			}
		});
		if (poly.length > 0) {
			let li = document.createElement('li');
			li.textContent = 'Zone personnalisée';
			this.ul.appendChild(li);
		}
	}
	create_li(txt) {
		let li = document.createElement('li');
		li.textContent = txt;
		this.ul.appendChild(li);
	}

	/*--------------------------------------------------------------
	-	Submit
	--------------------------------------------------------------*/
	submit(e) {
		e.preventDefault();
		if (this.pending) return;
		this.loading();
		let p = page.sr ? page.sr.params : page.sh.params;

		// if achat & louer -> achat & louer = false (to get every lot)
		this.vars = {
			params: {
				general: {},
				statut: {},
				surfaces: {},
				vente: {},
				location: {
					loyer_annuel: {},
				},
				adresse: {},
			},
		};

		if (p.t === 'louer') {
			this.vars.params.statut.a_louer = true;
		} else if (p.t === 'achat') {
			this.vars.params.statut.a_vendre = true;
		} else if (p.t === 'louer,achat') {
			this.vars.params.statut.a_louer = true;
			this.vars.params.statut.a_vendre = true;
		}

		// surface / prix
		if (p.surface_max) {
			this.vars.params.surfaces.superficie_max = Number(p.surface_max);
		}
		if (p.surface_min) {
			this.vars.params.surfaces.superficie_min = Number(p.surface_min);
		}

		if (this.vars.params.statut.a_louer && this.vars.params.statut.a_vendre) {
			if (p.prix_max) {
				this.vars.params.vente.prix_max =
					this.vars.params.location.loyer_annuel.ht_hc_max = p.prix_max;
			}
			if (p.prix_min) {
				this.vars.params.vente.prix_min =
					this.vars.params.location.loyer_annuel.ht_hc_min = p.prix_min;
			}
		} else if (this.vars.params.statut.a_louer) {
			if (p.prix_max) {
				this.vars.params.location.loyer_annuel.ht_hc_max = p.prix_max;
			}
			if (p.prix_min) {
				this.vars.params.location.loyer_annuel.ht_hc_min = p.prix_min;
			}
		} else if (this.vars.params.statut.a_vendre) {
			if (p.prix_max) {
				this.vars.params.vente.prix_max = p.prix_max;
			}
			if (p.prix_min) {
				this.vars.params.vente.prix_min = p.prix_min;
			}
		}

		let selectedTypes = null;
		if (p.types) {
			p.types = p.types.split(',');
			selectedTypes = p.types.map(
				(type) =>
					({
						1: 'Hangar',
						2: 'Entrepôt',
						3: 'Entrepôt frigorifique',
						4: 'Atelier',
						16: "Local d'activités",
						17: 'Local commercial',
						18: 'Bureau',
						19: 'Terrain',
						20: 'Locaux mixtes',
					}[type])
			);
		}

		this.vars.params.general.type = selectedTypes || null;

		this.vars.params.distance = Number(p.r) || null;

		if (typeof p.gps === 'string') {
			p.gps = JSON.parse(decodeURIComponent(p.gps));
		}
		if (p.r && p.r !== 0 && p.gps.length === 1) {
			this.vars.params.adresse.ville = [p.gps[0]._id];
		} else if (p.gps.length > 0) {
			// oui, je sais, un double chekc du length c'est caca, mais de toute façon faut refaire au propre toute la classe de Search
			if (p.gps.length > 0) {
				this.vars.params.adresse.ville = [];
				p.gps.forEach((_) => {
					this.vars.params.adresse.ville.push(_._id);
				});
			}
		}

		this.form.elements.forEach((el) => {
			if (el.name) {
				this.vars[el.name] = el.value;
			}
		});

		if (p.polygons) {
			p.polygons.coordinates.forEach((polygon) => {
				polygon.push(polygon[0]);
			});
			this.vars.params.polygons = p.polygons;
		}

		ajax
			.graphql({
				query: this.query,
				variables: this.vars,
			})
			.then(({ errors, data: { createAlert } }) => {
				if (errors) {
					this.done(false);
					console.error(errors);
				} else {
					this.done(createAlert.id !== 0);
				}
			});
	}

	/*--------------------------------------------------------------
	-	Loading
	--------------------------------------------------------------*/
	loading() {
		this.pending = true;
		this.form.classList.add('hidden');
		this.popup.classList.add('loading');
	}

	/*--------------------------------------------------------------
	-	Done
	--------------------------------------------------------------*/
	done(ok) {
		this.pending = false;
		this.popup.classList.remove('loading');
		this.response.classList.remove('hidden');
		if (ok) {
			this.res_success.classList.remove('hidden');
		} else {
			this.response.classList.add('fail');
			this.res_failure.classList.remove('hidden');
		}
	}

	/*--------------------------------------------------------------
	-	Reset
	--------------------------------------------------------------*/
	reset() {
		this.form.classList.remove('hidden');
		this.response.classList.add('hidden');
		this.response.classList.remove('fail');
		this.res_success.classList.add('hidden');
		this.res_failure.classList.add('hidden');
	}
}
