/*--------------------------------------------------------------
ROUTING  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Home
- Recherche
- Lot
- Contact
- Qui sommes nous
- Nos conseils
- Dictionnaire
- Article
- Testimonial
--------------------------------------------------------------*/

class Page {
	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor() {
		// Recherche
		let graphqlURL = 'https://api.immo2pointzero.fr/';
		// if (window.location.hostname === 'localhost') {
		// 	graphqlURL = 'http://localhost:7777';
		// } else if (
		// 	window.location.hostname === 'www.scamac-immo.new-serv.adelios.fr'
		// ) {
		// 	graphqlURL = 'https://backend-scamac.new-serv.adelios.fr/';
		// }

		ajax.setGraphqlURL(graphqlURL);

		// Gmap
		this.apiKey = 'AIzaSyBxughfURQ3UoBRzAjPh0qQJWHM1oMnrWc';
		this.scriptTag = document.createElement('script');
		document.body.appendChild(this.scriptTag);
		this.scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,drawing`;

		// Init
		if (this[$$.first('.main').id]) {
			this[$$.first('.main').id]();
		}

		// Burger
		$$.id('burger').onclick = (_) =>
			$$.tags('header')[0].classList.toggle('open');

		// Back
		$$.classes('back').forEach((el) =>
			el.on('click', (e) => {
				e.preventDefault();
				window.history.back();
			})
		);

		this.numero();

		// Chat
		this.chat = new Chat({
			ssl: true,
			btn: $$.first('.btn_chat'),
			// host: 'scamac-chat.dev.adelios.fr',
			host: 'chat.scamac-immo.com',
			robotDelay: 400,
			waitForAdmin: 60000,
		});
		this.maitlo();
	}

	/*--------------------------------------------------------------
-	Mailto
--------------------------------------------------------------*/
	maitlo() {
		if ((this.mailto = $$.classes('open-mailto'))) {
			let intervalId = setInterval((_) => {
				if (this.mailto.length > 0) {
					clearInterval(intervalId);
					this.mailto.forEach((el) => {
						el.addEventListener('click', (_) => {
							mailto.open(
								el.getAttribute('data-id'),
								el.getAttribute('data-ref')
							);
						});
					});
				}
			}, 500);
		}
	}

	/*--------------------------------------------------------------
	-	Home
	--------------------------------------------------------------*/
	home() {
		new Scroll($$.first('.scroll'));
		this.delete_alert = new DeleteAlert();
		new Slider('hero', 6000, 'section', '.slider-nav input');
		this.sh = new Search_home();
		new ModuleHome('module_home', this.sh);
		this.scriptTag.onload = () => {
			this.sh.autocomplete();
		};

		tmpl.preload(['vignette']).then((_) => {
			if (cookie.get('recherche')) {
				let last_search = JSON.parse(cookie.get('recherche'));
				let ids = [];
				let zone_perso = [];

				if (last_search.gps) {
					let last_search_gps = JSON.parse(decodeURIComponent(last_search.gps));
					last_search_gps.forEach((e) => ids.push(e._id));
				}
				if (last_search.perso) {
					zone_perso = JSON.parse(decodeURIComponent(last_search.perso));
				}

				vignettes.removeChildren('#biens-custom ul');

				// Recos
				vignettes.append('#biens-custom ul', {
					limit: 3,
					filters: {
						vente: last_search.t === 'louer' ? false : true,
						location: last_search.t === 'louer' ? true : false,
						villes: ids.length > 0 ? ids : null,
						types: last_search.types ? last_search.types.split(',') : null,
						polygons: last_search.perso ? zone_perso : null,
						surface_min: last_search.surface_min,
						surface_max: last_search.surface_max,
					},
					order_by: {
						order: 'RAND',
					},
				});
			} else {
				/*vignettes.append('#biens-custom ul', {
					limit: 3,
					filters: {
						vente : true,
					},
					order_by: {
						order: 'RAND',
					},
				});*/
			}

			// Derniers biens
			/*vignettes.append('#derniers-biens ul', {
				limit: 3,
				filters: {
					vente: true,
				},
				order_by: {
					field: 'DATE',
					order: 'DESC',
				},
			});*/
		});
	}

	/*--------------------------------------------------------------
	-	Recherche
	--------------------------------------------------------------*/
	recherche() {
		this.sr = new Search_result();
		this.scriptTag.onload = (_) => {
			this.sr.autocomplete();
		};
	}

	/*--------------------------------------------------------------
	-	Lot
	--------------------------------------------------------------*/
	page_lot() {
		function download_lot_pdf(idLot) {
			ajax
				.graphql({
					query: `query _($ids: [ID!]!) {
                        pdf: pdfLotsByID(ids: $ids) {
                            path
                            files
                            succes
                            message
                            queryIndex
                        }
                    }`,
					variables: { ids: [idLot] },
				})
				.then(({ data }) => {
					let a = document.createElement('a');
					a.setAttribute('href', encodeURI(data.pdf.path[0]));
					a.setAttribute('target', '_blank');
					a.setAttribute('download', data.pdf.files[0]);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				});
		}
		window.exportLot = download_lot_pdf;
		new Slider('galerie', 6000, 'figure', '.slider-nav input');
		new Lot();
	}

	/*--------------------------------------------------------------
	-	Contact
	--------------------------------------------------------------*/
	contact() {
		this.scriptTag.onload = () => {
			new contactMap('carte');
		};
		new ContactForm('contact_form');
		new Recommandation(1);
	}

	/*--------------------------------------------------------------
	-	Qui sommes nous
	--------------------------------------------------------------*/
	qsn() {
		this.btn = $$.all('.voir_plus');
		this.click = new Reveal('qsn');
		this.btn.forEach((button) => {
			button.onclick = (_) => {
				setTimeout((_) => this.click.reveal(), 100);
			};
		});
		var x = $$.first('.clients');
		$$.id('tc').addEventListener('click', (_) => {
			x.children[1].children.forEach(function (el) {
				el.classList.remove('reveal');
				$$.id('tc').parentElement.style.display = 'none';
			});
		});

		var y = $$.first('.onparle');
		$$.id('opn').addEventListener('click', (_) => {
			y.children.forEach(function (el) {
				el.classList.remove('reveal');
				$$.id('opn').parentElement.style.display = 'none';
			});
		});
	}

	/*--------------------------------------------------------------
	-	Nos conseils
	--------------------------------------------------------------*/
	conseils() {
		new Reveal('conseils');
		new Recommandation(1);
	}

	/*--------------------------------------------------------------
	-	Dictionnaire
	--------------------------------------------------------------*/
	dico() {
		new Recommandation(1);
	}

	/*--------------------------------------------------------------
	-	Article
	--------------------------------------------------------------*/
	article() {
		new Recommandation(1);
	}

	/*--------------------------------------------------------------
	-	Testimonial
	--------------------------------------------------------------*/
	testimonial() {
		new Recommandation(1);
	}

	/*--------------------------------------------------------------
	-	Decouverte numero
	--------------------------------------------------------------*/
	numero() {
		$$.classes('tel').forEach((el) =>
			el.on('click', (event) => {
				if (el.classList.contains('dev')) {
					event.preventDefault();
					el.classList.remove('dev');

					// Google
					gtag_report_conversion();

					// Bing
					window.uetq = window.uetq || [];
					window.uetq.push('event', 'boutton', {
						event_category: 'clic',
						event_label: 'affichernumero',
						event_value: '1',
					});
				}
			})
		);
	}
}

/*--------------------------------------------------------------
-	Init
--------------------------------------------------------------*/
const tmpl = new Template();
const vignettes = new Vignette();
const popup = new Popup('popup');
const sidebar = new Sidebar('sidebar');
const alerte = new Alert('alert');
const mailto = new Mailto('mailto');
const page = new Page();
